import React, { useState, useEffect } from 'react'
import {
  useMoralis,
  useMoralisWeb3Api,
  useWeb3ExecuteFunction,
} from 'react-moralis'
import APIService from './service/APIService'
import { useMoralisDapp } from './providers/MoralisDappProvider/MoralisDappProvider'

const dataMoking = {
  timeStaking: [
    { day: 10, value: 1 }, { day: 20, value: 1.5 }, { day: 30, value: 2 }
  ],
  itemInfo: [
    {
      money: 12,
      name: "Lucky Star 1",
      factor: [
        25, 50, 100
      ],
      urlImage: "https://www.shareicon.net/data/128x128/2016/08/01/640629_star_512x512.png"
    },
    {
      money: 32,
      name: "Lucky Star 2",
      factor: [
        100, 150, 200
      ],
      urlImage: "https://www.shareicon.net/data/128x128/2016/08/01/640629_star_512x512.png"
    },
    {
      money: 52,
      name: "Lucky Star 3",
      factor: [
        200, 250, 300
      ],
      urlImage: "https://www.shareicon.net/data/128x128/2016/08/01/640629_star_512x512.png"
    },
    {
      money: 72,
      name: "Lucky Star 4",
      factor: [
        300, 350, 400
      ],
      urlImage: "https://www.shareicon.net/data/128x128/2016/08/01/640629_star_512x512.png"
    }
  ]
}

const Staking = () => {
  const { walletAddress } = useMoralisDapp()
  const [indexItem, setIndexItem] = useState(0)
  const [indexDay, setIndexDay] = useState(0)
  const [type, setType] = useState(true)
  const [balance, setBalance] = useState(0)
  const { Moralis } = useMoralis()
  const Web3Api = useMoralisWeb3Api()
  const [mountConvert, setMountConvert] = useState(0)
  const contractProcessor = useWeb3ExecuteFunction()

  useEffect(() => {
    if (!walletAddress) return
    fetchNativeBalance()
  }, [walletAddress])

  const fetchNativeBalance = async () => {
    const options = {
      chain: 'bsc testnet',
      address: walletAddress,
      // to_block: "1234",
    }
    const bscBalance = await Web3Api.account.getTokenBalances(options)
    var newArray = bscBalance.filter((e) => {
      return e.symbol == 'GBS'
    })
    let _balance = Moralis?.Units?.FromWei(newArray[0].balance)
    setBalance(_balance)
  }



  return (
    <div className="lg:w-[1140px] lg:m-auto flex flex-col items-center p-4 lg:p-0">
      <div className="font-title text-4xl text-white self-start mt-12">
        STAKING
      </div>
      <div className="flex flex-row mt-6  justify-between w-full">
        <div className="flex flex-row h-9 items-center px-2">
          <img alt="" className="h-5 w-5" src="./images/gbs.png" />
          <p className="ml-2 w-full font-semibold">{parseFloat(balance).toFixed(2)}</p>
        </div>
        <div className='flex flex-row gap-4'>
        <div
          onClick={() => {
            setType(true)
          }}
          className={
            'rounded-lg w-24 h-8 text-center leading-8 cursor-pointer ' +
            (type
              ? 'border border-[#9DE40B] text-[#9DE40B]'
              : 'border-0 bg-gray-600')
          }
        >
          STAKING
        </div>
        <div
          onClick={() => {
            setType(false)
          }}
          className={
            'rounded-lg w-24 h-8 text-center leading-8 cursor-pointer ' +
            (!type
              ? 'border border-[#9DE40B] text-[#9DE40B]'
              : 'border-0 bg-gray-600')
          }
        >
          INFO
        </div>
        </div>
        
      </div>
      {type ? <div className="lg:w-[420px] sm:w-[420px] lg:m-auto sm:m-auto flex flex-col mx-2 w-full">
        <div className="flex flex-row gap-4 mt-7">
          <div className="flex  w-[30px] items-center  h-9">
            <img alt="" className="h-5 w-5" src="./images/gbs.png" />
          </div>
          <div className="flex grid grid-cols-2 gap-6 w-full">
            {dataMoking.itemInfo.map((item, index) => (
              <button onClick={() => setIndexItem(index)} className={"flex flex-row w-[100%] h-9  rounded-md items-center justify-center px-2 " +
                (indexItem === index
                  ? 'border border-[#9DE40B] text-[#9DE40B]'
                  : 'border-0 bg-[#262e36]')}>
                <p className="">
                  ${item.money.toFixed(2)}
                </p>
              </button>
            ))}
          </div>
        </div>
        <div className="flex flex-row gap-4 mt-4 py-4 border-y border-[#424858]">
          <div className="flex w-[30px] text-base items-center  h-9">
            Day
          </div>
          <div className="flex flex-row justify-between w-full">
            {dataMoking.timeStaking.map((item, index) => (
              <button onClick={() => setIndexDay(index)} className={"flex flex-row h-9 w-[110px] rounded-md items-center justify-center px-2 " +
                (indexDay === index
                  ? 'border border-[#9DE40B] text-[#9DE40B]'
                  : 'border-0 bg-[#262e36]')}>
                <p className="">
                  {String(item.day).padStart(2, '0')}
                </p>
              </button>
            ))}
          </div>
        </div>
        <div className="flex flex-row mt-4 w-full">
          <div className="flex w-[30px] text-base items-center  h-9">
            Gift
          </div>
          <div className="flex flex-row w-full ml-3">
            <img alt="" className="h-50" style={{ objectFit: "contain" }} src={dataMoking.itemInfo[indexItem].urlImage} />
            <div className="flex flex-col text-base ml-2 ">
              <p className="font-extrabold">
                {dataMoking.itemInfo[indexItem].name}
              </p>
              <p className="text-sm">
                Increase the chance to receive Items when <a class="text-cyan-400 font-extrabold">Losing</a> the match to <a class="text-cyan-400 font-extrabold">{dataMoking.itemInfo[indexItem].factor[0] * dataMoking.timeStaking[indexDay].value}%</a> <br />
                Increase the chance to receive Items when match <a class="text-yellow-300	 font-extrabold">Tie</a> to <a class="text-yellow-300	 font-extrabold">{dataMoking.itemInfo[indexItem].factor[1] * dataMoking.timeStaking[indexDay].value}%</a> <br />
                Increase the chance to receive Items when <a class="text-red-500		 font-extrabold">Winning</a> the match to <a class="text-red-500		 font-extrabold">{dataMoking.itemInfo[indexItem].factor[2] * dataMoking.timeStaking[indexDay].value}%</a>
              </p>
            </div>
          </div>
        </div>
        <button onClick={() => {

        }} className="w-[143px] self-center bg-[#9DE40B] rounded-full mt-8 h-9 flex items-center justify-center  text-black button-scale">
          <p className="font-extrabold">STAKE NOW</p>
        </button>
      </div> : <> <div className="h-[53px] w-full rounded-t-lg bg-[#252934] flex flex-row items-center justify-around mt-8 font-bold text-center px-2 ">
        <span className="w-14 sm:w-36 lg:w-50">NO</span>
        <span className="w-14 sm:w-36 lg:w-50">AMOUNT</span>
        <span className="w-14 sm:w-36 lg:w-50">START DAY</span>
        <span className="w-14 sm:w-36 lg:w-50">END DAY</span>
        <span className="w-14 sm:w-36 lg:w-50">DAY</span>
        <span className="w-14 sm:w-36 lg:w-50">CLAIM</span>
      </div>
        <div className="w-full overflow-auto min-h-[300px] rounded-b-lg bg-black bg-opacity-60 flex flex-col items-center mb-8 gap-5 pt-4 pb-4 px-2">
          {[1, 2, 3, 4, 5, 6, 8].map((e, i) => {
            return (
              <div
                key={i}
                className="w-full  flex flex-row items-center justify-around text-center"
              >
                <span className="w-14 sm:w-36 lg:w-50">
                  {i + 1}
                </span>
                <div>
                  <span className="flex w-14 sm:w-36 lg:w-50 font-black uppercase seft-start sm:pl-12">
                    {e}
                  </span>
                </div>
                <span className="w-14 sm:w-36 lg:w-50">
                  #{e}
                </span>
                <span className="w-14 sm:w-36 lg:w-50">
                  #{e}
                </span>
                <span className="w-14 sm:w-36 lg:w-50">
                  #{e}

                </span>
                <span className="w-14 sm:w-36 lg:w-50 flex justify-center">
                  <button onClick={() => {

                  }} className="rounded-xl px-2 py-1 flex items-center justify-center border border-[#9DE40B] text-[#9DE40B] button-scale">
                    <p className="font-extrabold">CLAIM</p>
                  </button>

                </span>

              </div>
            )
          })}
        </div>
        {/* <div className='flex flex-grow flex-col items-center'>
          <Pagination
            className=""
            current={currentPage}
            onChange={(page) => {
              setCurrentPage(page)
            }}
            showSizeChanger={false}
            total={totalItem}
            hideOnSinglePage={false}
            pageSize={10}
          />
        </div> */}
      </>}
    </div>
  )
}

export default Staking
