const Instruction = () => {
  return (
    <div className="w-full bg-contain bg-bg-instruction bg-center bg-no-repeat ">
      <div className="lg:w-[1080px] sm:w-[700px] sm:m-auto flex flex-col items-start p-4 lg:p-0">
        <strong className="text-xl">What is GoBall?</strong>
        <p>GoBall is a combination of passion for football and blockchain
        technology. We desire to create a platform not just for NFT collection,
        but also a platform in which users can equip their NFT players with
        beautiful hand-crafted items, and experience the thrill of fast-paced
        multiplayer soccer matches! In GoBall, players are able to play for free
        everyday and earn the tickets to participate in exclusive fiery matches
        to achieve tournaments’ grand prizes worth up to 10.000% ROI. With the
        more masterful skill set, the more valuable items you can find in our
        game and trade them on  <a className='font-bold text-[#00CCFF]' href='/items' target="_blank">GoBall’s marketplace</a>. Each character has an equal
        strength. Goball relies on skill and luck to find valuable items.
        </p>
        <br />
        <strong className="text-xl">Highlight Features:</strong>
        • Own and take control of a unique NFT Easy and fun to play.
        <br />
        • Spice things up with beautiful equipments
        <br />
        • Challenge other players from all-over the world in real-time
        <br />
        • Big Rewards Are Waiting For The Champions
        <br />
        <div className="w-full flex lg:flex-row lg:gap-0 gap-3 flex-col lg:w-[1080px] sm:w-[500px] sm:m-auto sm:mt-20 justify-between mt-20 lg:mt-32 items-center grid grid-cols-2 lg:grid-cols-4">
          <div className="relative m-auto">
            <img
               className="absolute left-11 sm:left-14 -top-[50px]"
              src="/images/intro-1.png"
              alt="img"
            />
            <div className="bg-item-frame bg-cover sm:h-226 sm:w-211 h-[196px] w-[184px] p-4 pt-16 text-sm">
              <p className="text-white font-bold uppercase">
                Slab Run
              </p>

              <p className="text-[11px] sm:text-[14px]">
                Wanna break the siege, speed up and attack unexpectedly? Use
                this item to reduce the opponent's movement speed to 50% to
                prevail.
              </p>
            </div>
          </div>
          <div className="relative m-auto">
            <img
              className="absolute left-11 sm:left-14 -top-[50px]"
              src="/images/intro-2.png"
              alt="img"
            />
            <div className="bg-item-frame bg-cover sm:h-226 sm:w-211 h-[196px] w-[184px] p-4 pt-16 text-sm">
              <p className="text-white font-bold uppercase">Helios Jump</p>

              <p className="text-[11px] sm:text-[14px]">
                Dominate with a high kick, surprise opponent with high bounce
                and put the ball into the opponent's net.
              </p>
            </div>
          </div>
          <div className="relative mt-20 sm:m-auto sm:mt-28 lg:mt-0">
            <img
             className="absolute left-11 sm:left-14 -top-[50px]"
              src="/images/intro-3.png"
              alt="img"
            />
            <div className="bg-item-frame bg-cover sm:h-226 sm:w-211 h-[196px] w-[184px] p-4 pt-16 text-sm">
              <p className="text-white font-bold uppercase">Evolve Potion</p>
              <p className="text-[11px] sm:text-[14px]">
                Increasing the size of the character to help you prevent all
                attacks from the opponent when sending the ball into the goal
              </p>
            </div>
          </div>
          <div className="relative mt-20 sm:m-auto sm:mt-28 lg:mt-0">
            <img
            className="absolute left-11 sm:left-14 -top-[50px]"
              src="/images/intro-4.png"
              alt="img"
            />
            <div className="bg-item-frame bg-cover sm:h-226 sm:w-211 h-[196px] w-[184px] p-4 pt-16 text-sm">
              <p className="text-white font-bold uppercase">Devolve Potion</p>
              <p className="text-[11px] sm:text-[14px]">
                Opponent's character is reduced to 50% of its original size
                creating great chance for attack with high kicks to bring the
                ball into the goal
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center justify-center w-full mt-20">
          <img src="/images/ins-char-new.png" alt="charnew" />
        </div>
        <div className="flex flex-row w-full items-center h-[400px]">
          <div className="w-1/2 flex hidden lg:block">
            <img src="/images/ins-3.png" alt="ins1" />
          </div>
          <div className="w-full lg:w-1/2">
            <div className="w-full text-left font-bold text-4xl">CHARACTER</div>
            <p>
              Choose your favorite unique <a className='font-bold text-[#00CCFF]' href='/heroes' target="_blank">GoBall Player</a> and join thrilling
              real-time interactive matches with other players. Master all
              techniques to overwhelm your opponent with great kicks and send
              the ball into the goal.
              <br />
              The characters are allowed to use items to increase their winning
              chance.
              <br />
              The winners can get valuable NFT items.
            </p>
            <div className="w-full mt-3.5 block lg:hidden">
              <img src="/images/ins-3.png" alt="ins1" />
            </div>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row w-full justify-between  h-[300px] sm:h-[400px] mb-20">
          <div className="w-full lg:w-1/2">
            <div className="w-full text-left font-bold text-4xl">ARENA</div>
            <p>
              Arena take place daily, players compete for attractive prizes of
              NFT items and the chance to win tickets to Global Championship.
              With this big tournament, players enter the pro game to win cash
              prizes and special NFT characters other players craving for.
            </p>
          </div>
          <div className="w-full sm:w-fit lg:w-1/2 sm:m-auto">
            <img src="/images/ins-2.png" alt="ins1" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Instruction
