import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import Heroes from './Heroes'
import Items from './Items'
import HeroesDetail from './HeroesDetail'
import ItemDetail from './ItemDetail'
import Tokenomic from './Tokenomic'
import Inventory from './Inventory'
import ComingSoon from './ComingSoon'
import Instruction from './Instruction'
import Header from './components/Header'
import Footer from './components/Footer'
import 'antd/dist/antd.css'
import './index.css'
import { BrowserRouter } from 'react-router-dom'
import { Routes, Route, useParams } from 'react-router-dom'
import { MoralisProvider } from 'react-moralis'
import { MoralisDappProvider } from './providers/MoralisDappProvider/MoralisDappProvider'
import { useState, useEffect } from 'react'
import TermsOfServices from './TermsOfServices'
import Ingame from './Ingame'
import APIService from "./service/APIService"
import Ref from './Ref'
import UserManual from './UserManual'
import Airdrop from './Airdrop'
import Ipo from './Ipo'
import TokenTransfer from './TokenTransfer'
import ItemTransfer from './ItemTransfer'
import LoginGame from './LoginGame'
import RefGame from './RefGame'
import Social from './components/Social'
import Staking from './Staking'

async function getAppConfig() {
  try {
    const response = await APIService.getAppConfigs()
    return response
  } catch (error) {
    console.error(error)
  }
}

const Application = () => {
  useEffect(() => {
    async function get() {
      let dataConfig = await getAppConfig()
      window.localStorage.setItem(
        'MARKET_NFT_ADDRESS',
        dataConfig.MARKET_NFT_ADDRESS
      )
      window.localStorage.setItem('MORALIS_APP_ID', dataConfig.MORALIS_APP_ID)
      window.localStorage.setItem(
        'MORALIS_SERVER_URL',
        dataConfig.MORALIS_SERVER_URL
      )
      window.localStorage.setItem('NFT_ADDRESS', dataConfig.NFT_ADDRESS)
      window.localStorage.setItem('TOKEN_ADDRESS', dataConfig.TOKEN_ADDRESS)
      window.localStorage.setItem('ADMIN_ADDRESS', dataConfig.ADMIN_ADDRESS)
    }
    get()
  }, [])

  const APP_ID = window.localStorage.getItem('MORALIS_APP_ID')
  const SERVER_URL = window.localStorage.getItem('MORALIS_SERVER_URL')

  return (
    <MoralisProvider appId={APP_ID} serverUrl={SERVER_URL}>
      <MoralisDappProvider>
        <div className='relative'>
          <Routes>
            <Route path="/" element={<Header path="/" />} />
            <Route path="heroes" element={<Header path="/heroes" />} />
            <Route path="items" element={<Header path="/items" />} />
            <Route path="tokenomic" element={<Header path="/tokenomic" />} />
            <Route path="inventory" element={<Header path="/inventory" />} />
            <Route path="comingsoon" element={<Header path="/comingsoon" />} />
            <Route path="instruction" element={<Header path="/instruction" />} />
            <Route path="termsofservices" element={<Header path="" />} />
            <Route path="ingame" element={<Header path="/ingame" />} />
            <Route path="usermanual" element={<Header path="/usermanual" />} />
            <Route path="ipo" element={<Header path="/ipo" />} />
            <Route path="tokentransfer" element={<Header path="/tokentransfer" />} />
            <Route path="itemtransfer" element={<Header path="/itemtransfer" />} />
            <Route path="logingame" element={<Header path="/logingame" />} />
            <Route path="refgame" element={<Header path="/refgame" />} />
            <Route
              path="ref/:id"
              element={<Header path="/ref" />}
            />
            <Route
              path="airdrop"
              element={<Header path="/airdrop" />}
            />
            <Route
              path="heroes-detail/:id/:type"
              element={<Header path="/heroes-detail" />}
            />
            <Route
              path="heroes-detail/:id/:type/:tokenId"
              element={<Header path="/heroes-detail" />}
            />
            <Route
              path="items-detail/:id/:type"
              element={<Header path="/items-detail" />}
            />
            <Route
              path="items-detail/:id/:type/:tokenId"
              element={<Header path="/items-detail" />}
            />
            <Route
              path="staking"
              element={<Header path="/staking" />}
            />
          </Routes>

          <Routes>
            <Route path="/" element={<App />} />
            <Route path="heroes" element={<Heroes />} />
            <Route path="items" element={<Items />} />
            <Route path="tokenomic" element={<Tokenomic />} />
            <Route path="heroes-detail/:id/:type" element={<HeroesDetail />} />
            <Route
              path="heroes-detail/:id/:type/:tokenId"
              element={<HeroesDetail />}
            />
            <Route path="items-detail/:id/:type" element={<ItemDetail />} />
            <Route
              path="items-detail/:id/:type/:tokenId"
              element={<ItemDetail />}
            />
            <Route
              path="tokentransfer"
              element={<TokenTransfer />}
            />
            <Route
              path="itemtransfer"
              element={<ItemTransfer />}
            />
            <Route
              path="ref/:id"
              element={<Ref />}
            />
            <Route
              path="airdrop"
              element={<Airdrop />}
            />
            <Route path="refgame" element={<RefGame />} />
            <Route path="logingame" element={<LoginGame />} />
            <Route path="inventory" element={<Inventory />} />
            <Route path="ipo" element={<Ipo />} />
            <Route path="termsofservices" element={<TermsOfServices />} />
            <Route path="comingsoon" element={<ComingSoon />} />
            <Route path="instruction" element={<Instruction />} />
            <Route path="ingame" element={<Ingame />} />
            <Route path="usermanual" element={<UserManual />} />
            <Route path="staking" element={<Staking />} />
          </Routes>
          {/* <div className='absolute top-[50px] sm:right-10 h-full w-full'> */}
            <Social />
          {/* </div> */}
        </div>
        <Footer />
      </MoralisDappProvider>
    </MoralisProvider>
  )
}

ReactDOM.render(
  <BrowserRouter>
    <Application />
  </BrowserRouter>,
  document.getElementById('root')
)
