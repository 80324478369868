import { Link, useLocation } from 'react-router-dom'
import { Button, Tooltip } from 'antd';

const Social = () => {
  const { pathname } = useLocation()
  console.log(pathname);
  return (
    <div className='sticky z-40 bottom-[60px] flex sm:flex-col  h-16 sm:h-0 sm:justify-end  sm:items-end sm:mr-10'>
      <div className='sm:gap-4 flex sm:flex-col sm:items-center w-full sm:w-fit justify-around'>
        <a href="https://t.me/GoballChannel" target="_blank" className='flex flex-col items-center'>
          <img src="/images/telegram.png" className='sm:h-16 sm:w-16 h-12 w-12' />
          <p className='mt-1 sm:text-xs text-[7px]'>GoBall Official<br />Announcement</p>
        </a>
        <a href="https://t.me/GoBallOffical" target="_blank" className='flex flex-col items-center'>
          <img src="/images/telegram.png" className='sm:h-16 sm:w-16 h-12 w-12' />
          <p className='mt-1 sm:text-xs text-[7px]'>GoBall Offical</p>
        </a>
        <a href="https://t.me/GoBallMarket" target="_blank" className='flex flex-col items-center'>
          <img src="/images/telegram.png" className='sm:h-16 sm:w-16 h-12 w-12' />
          <p className='mt-1 sm:text-[11px] text-[7px]'>GoBall Market</p>
        </a>
        <a href="https://www.facebook.com/Goball-100135432647036/" target="_blank">
          <img src="/images/facebook.png" className='sm:h-16 sm:w-16 h-12 w-12' />
        </a>
        <a href="https://twitter.com/GoBall_io" target="_blank">
          <img src="/images/twitter.png" className='sm:h-16 sm:w-16 h-12 w-12' />
        </a>
        <Link to="/comingsoon">
          <img src="/images/discord.png" className='sm:h-16 sm:w-16 h-12 w-12' />
        </Link>
        <a href="https://goball.medium.com/" target="_blank">
          <img src="/images/m.png" className='sm:h-16 sm:w-16 h-12 w-12' />
        </a>
      </div>

    </div>
  )
}

export default Social
