import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useMoralis } from 'react-moralis'
import { Pagination } from 'antd'
import { priceFormat } from './helpers/formatters'
import APIService from "./service/APIService"

async function getAppStats() {
  try {
    const response = await APIService.getAppStats()
    return response
  } catch (error) {
    console.error(error)
  }
}

async function getCategories() {
  try {
    const response = await APIService.getCategories(
      "ITEM"
    )
    return response.categories
  } catch (error) {
    console.error(error)
  }
}

async function getMarketplace(cate, itemID, orderField, orderDirection, page) {
  let _start = (page - 1) * 9
  try {
    let data = {
      start: _start,
      limit: 9,
      onSale: true,
    }
    if (cate != null && cate !== '') {
      data['categoryId'] = cate
    }
    if (itemID != null && itemID !== '') {
      data['itemId'] = itemID
    }
    if (orderField != null && orderField !== '') {
      data['orderField'] = orderField
    }
    if (orderDirection != null && orderDirection !== '') {
      data['orderDirection'] = orderDirection
    }
    const response = await APIService.getMarketplaceItem(
      data
    )
    return response
  } catch (error) {
    console.error(error)
  }
}

async function getBNBPrice() {
  try {
    const response = await APIService.getBNBPrice()
    return response
  } catch (error) {
    console.error(error)
  }
}
const Items = () => {
  const [isShowFilter, setIsShowFilter] = useState(false)
  const [heroes, setHeroes] = useState([])
  const [categories, setCategories] = useState([])
  const [filterCate, setFilterCate] = useState('')
  const [filterItemId, setFilterItemId] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [bnbPrice, setBNBPrice] = useState(0)
  const { Moralis } = useMoralis()
  const [rowPage, setRowPage] = useState([])
  const [sortId, setSortId] = useState('1')
  const [totalItem, setTotalItem] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [dataStats, setDataStats] = useState({})

  useEffect(() => {
    async function get() {
      let bnbP = await getBNBPrice()
      setBNBPrice(bnbP)
      let cates = await getCategories()
      setCategories(cates)
      let stats = await getAppStats()
      setDataStats(stats)
    }
    get()
    if (window.outerWidth < 500) {
      setIsShowFilter(false)
    } else {
      setIsShowFilter(true)
    }
  }, [])

  useEffect(() => {
    async function listHero() {
      setIsLoading(true)
      let orderField = ''
      let orderDirection = ''

      switch (sortId) {
        case '1':
          //Newest
          orderField = 'updatedAt'
          orderDirection = 'DESC'
          break
        case '2':
          //Oldest
          orderField = 'updatedAt'
          orderDirection = 'ASC'
          break
        case '3':
          //Highest Price
          orderField = 'price'
          orderDirection = 'DESC'
          break
        case '4':
          //Lowest Price
          orderField = 'price'
          orderDirection = 'ASC'
          break
      }

      let data = await getMarketplace(
        filterCate,
        filterItemId,
        orderField,
        orderDirection,
        currentPage
      )
      setHeroes(data.items)
      setTotalItem(data.total)
      setIsLoading(false)
    }
    listHero()
  }, [filterCate, filterItemId, sortId, currentPage])

  return (
    <div className="w-full bg-marketplace bg-center bg-no-repeat ">
      <div className="p-2 lg:w-[907px] lg:m-auto flex flex-col items-center">
        <img alt="" className="my-6" src="./images/line.png" />
        <div className="flex flex-row justify-between w-full">
          <div>
            <p className="text-center">Items Sold</p>
            <p className="text-center text-xl lg:text-4xl font-bold text-main-orange">
              {priceFormat(dataStats.itemSold)}
            </p>
          </div>
          <div>
            <p className="text-center">Total Volume</p>
            <p className="text-center text-xl lg:text-4xl font-bold text-main-orange">
              {priceFormat(dataStats.itemVolume, 'BNB')}
            </p>
          </div>
          <div>
            <p className="text-center">Highest Price</p>
            <p className="text-center text-xl lg:text-4xl font-bold text-main-orange">
              {priceFormat(dataStats.itemHighestPrice, 'BNB')}
            </p>
          </div>
        </div>

        <img alt="" className="my-6" src="./images/line.png" />
        <div className="flex flex-col lg:flex-row w-full justify-between">
          <div className="lg:w-[337px]">
            <div
              onClick={() => {
                setIsShowFilter(!isShowFilter)
              }}
              className="bg-gray-600 p-4 rounded-xl mb-4 block lg:hidden"
            >
              <div className="flex flex-row justify-between">
                <span className="font-bold text-main-green-light">Filter</span>
                {isShowFilter ? (
                  <i className="fa-solid fa-angle-up"></i>
                ) : (
                  <i className="fa-solid fa-angle-down"></i>
                )}
              </div>
            </div>
            {isShowFilter && (
              <div className="bg-black p-4 rounded-md">
                <div className="flex flex-row justify-between">
                  <span className="font-bold">FILTERS</span>
                  <button
                    className="text-red-500"
                    onClick={() => {
                      setSortId('1')
                      setCurrentPage(1)
                      setFilterCate('')
                      setFilterItemId('')
                    }}
                  >
                    Clear filter
                  </button>
                </div>
                <div className="flex flex-row justify-between gap-4 mt-2">
                  <div className="w-full">
                    <p>Sort By</p>
                    <select
                      className="w-full bg-[#262e36] rounded-md mt-1 h-8 px-1"
                      onChange={(e) => {
                        setCurrentPage(1)
                        setSortId(e.target.value)
                      }}
                    >
                      <option value="1">Newest</option>
                      <option value="2">Oldest</option>
                      <option value="3">Highest Price</option>
                      <option value="4">Lowest Price</option>
                    </select>
                  </div>
                  <div>
                    <p>Item ID</p>
                    <input
                      type="text"
                      name="ItemID"
                      className="w-full bg-main-black rounded-md mt-1 h-8 px-1"
                      onChange={async (e) => {
                        setCurrentPage(1)
                        setFilterItemId(e.target.value)
                      }}
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-4 mt-4">
                  <div className="flex flex-row items-center gap-2">
                    <span className="font-bold">Types</span>
                    <span className="w-full border-t border-gray-600"></span>
                  </div>
                  {categories.map((e) => {
                    return (
                      <div
                        key={e._id}
                        className="flex flex-row items-center gap-4"
                      >
                        <img alt="" src="./images/ico3.png" />
                        <span className="flex-grow">{e.name}</span>
                        <div className="custom-checkbox">
                          <input
                            type="checkbox"
                            id={e._id}
                            checked={e._id === filterCate}
                            onClick={async (i) => {
                              setCurrentPage(1)
                              if (e._id === filterCate) {
                                setFilterCate('')
                              } else {
                                setFilterCate(e._id)
                              }
                            }}
                            onChange={() => { }}
                          />
                          <label htmlFor={e._id}></label>
                        </div>
                      </div>
                    )
                  })}
                </div>

                {/* <div className="flex flex-col gap-4 mt-2">
                <div className="flex flex-row items-center gap-2 mt-4">
                  <span className="w-32">Trait Rarity</span>
                  <span className="w-full border-t border-gray-600"></span>
                </div>
                <div className="grid grid-cols-2 gap-3">
                  <div className="h-8 w-[143px] rounded-md border border-main-green-light bg-[#343D52] font-bold text-main-green-light text-center leading-8">
                    COMMON
                  </div>
                  <div className="h-8 w-[143px] rounded-md  bg-[#343D52] font-bold text-gray-500 text-center leading-8">
                    UNCOMMON
                  </div>
                  <div className="h-8 w-[143px] rounded-md  bg-[#343D52] font-bold text-gray-500 text-center leading-8">
                    RARE
                  </div>
                  <div className="h-8 w-[143px] rounded-md  bg-[#343D52] font-bold text-gray-500 text-center leading-8">
                    EPIC
                  </div>
                  <div className="h-8 w-[143px] rounded-md  bg-[#343D52] font-bold text-gray-500 text-center leading-8">
                    LEGENDARY
                  </div>
                  <div className="h-8 w-[143px] rounded-md  bg-[#343D52] font-bold text-gray-500 text-center leading-8">
                    MYTHICAL
                  </div>
                </div>
              </div> */}
              </div>
            )}
          </div>
          <div className="flex flex-grow flex-col min-w-[65%] items-center mt-4 lg:mt-0 lg:ml-4">
            {isLoading ? (
              <div className="w-full text-center min-h-[900px]">Loading</div>
            ) : (
              <div className='min-h-[900px]'>
                <div className="grid grid-cols-2 lg:grid-cols-3 gap-3.5 ">
                  {heroes.map((e) => {
                    return (
                      <Link
                        key={'hero' + e._id}
                        to={'/items-detail/' + e.itemId + '/buy'}
                      >
                        <div className="bg-hero-frame h-288 w-176 bg-center bg-no-repeat pt-8 px-5">
                          <div className="flex flex-col ">
                            <div className="flex flex-row justify-between">
                              <span className="bg-pink-600 rounded-lg text-xs px-1">
                                #{e.itemId}
                              </span>
                              <span className="text-xs">Level 1</span>
                            </div>
                            <img alt="" className="my-2" src={e.imageUrl} />
                            <div className="flex flex-row justify-center gap-3 items-center">
                              <img
                                alt=""
                                className="h-5 w-5"
                                src="./images/gbs.png"
                              />
                              <span className="text-xl font-bold">
                                {Moralis?.Units?.FromWei(e.salePrice, 18)} GBS
                              </span>
                            </div>
                            <span className="text-main-orange text-xs w-full text-center">
                              $
                              {Math.round(
                                bnbPrice *
                                Moralis?.Units?.FromWei(e.salePrice, 18)
                              )}
                            </span>
                          </div>
                        </div>
                      </Link>
                    )
                  })}
                </div>
              </div>
            )}
            <Pagination
              className="mt-10"
              current={currentPage}
              onChange={(page) => {
                setCurrentPage(page)
              }}
              showSizeChanger={false}
              total={totalItem}
              hideOnSinglePage={false}
              pageSize={9}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Items
