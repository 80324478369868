import React, {useEffect, useLayoutEffect, useState} from 'react';
import { Spine } from 'pixi-spine';
import { Application, Container } from 'pixi.js';

export const createAnimationIcon = ({id, jsonURL, width, height, className, animScale = 1}) => {
  return () => {
    useLayoutEffect(() => {
      const app = new Application({
        backgroundAlpha: 0,
        width,
        height,
      });
      document.getElementById(id).appendChild(app.view);
      app.stop();

      app.loader.add('dragon', jsonURL).load(onAssetsLoaded);

      function onAssetsLoaded(loader, res) {
        // instantiate the spine animation
        const dragon = new Spine(res.dragon.spineData);
        dragon.skeleton.setToSetupPose();

        // choose skin
        const skins = res.dragon.spineData.skins.filter((skin) => skin.attachments.length != 0);
        const skin = skins[0];
        dragon.skeleton.setSkinByName(skin.name);
        dragon.skeleton.setSlotsToSetupPose();

        // create a container for the spine animation and add the animation to it
        const dragonCage = new Container();
        dragonCage.addChild(dragon);

        // measure the spine animation and position it inside its container to align it to the origin
        const localRect = dragon.getLocalBounds();
        dragon.position.set(-localRect.x, -localRect.y);

        // now we can scale, position and rotate the container as any other display object
        const scale = Math.min(
          (app.screen.width * animScale) / dragonCage.width,
          (app.screen.height * animScale) / dragonCage.height,
        );
        dragonCage.scale.set(scale, scale);
        dragonCage.position.set(
          (app.screen.width - dragonCage.width) * 0.5,
          (app.screen.height - dragonCage.height) * 0.5,
        );

        // add the container to the stage
        app.stage.addChild(dragonCage);
        // app.stage.on('pointertap', () => {
        //   currentSkinIndex = currentSkinIndex + 1 > skins.length - 1 ? 0 : currentSkinIndex + 1;
        // })

        // once position and scaled, set the animation to play
        dragon.state.setAnimation(0, 'animation', true);

        app.start();
      }
    }, []);

    return <div id={id} className={className} style={{
      width: width,
      height: height,
    }}/>
  }
}
