import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import APIService from "./service/APIService"
import { useMoralisDapp } from './providers/MoralisDappProvider/MoralisDappProvider'
import { message } from 'antd'
import {
  useWeb3ExecuteFunction,
  useMoralis,
  useWeb3Transfer
} from 'react-moralis'

const CONTRACT_ADDRESS = "0x58673E0d22b9E0A858f89E918fD52f2Cbf581183"
const CONTRACT_USDT = "0xAbe75ad1cE48203549fdE2824C2b19B30964A49F"

const Ipo = () => {
  let { id } = useParams()
  const { walletAddress } = useMoralisDapp()
  const contractProcessor = useWeb3ExecuteFunction()
  const { Moralis } = useMoralis()
  const { fetch, error, isFetching } = useWeb3Transfer({
    amount: Moralis.Units.Token(0.01, 18),
    receiver: CONTRACT_ADDRESS,
    type: "erc20",
    contractAddress: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
  });

  async function contractBuyPackage(packageId) {
    message.loading({ content: 'Loading...', key: "load", duration: 0 });
    const ops = {
      contractAddress: CONTRACT_ADDRESS,
      functionName: 'buyPackage',
      abi: [
        {
          inputs: [
            {
              internalType: "uint8",
              name: "packageId",
              type: "uint8"
            },
            {
              internalType: "address",
              name: "ref",
              type: "address"
            }
          ],
          name: "buyPackage",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function"
        }
      ],
      params: {
        packageId: packageId,
        ref: "0x6CA80D4F2491ABb108adCab53Bae1727Dc98F1a8",
      },
    }
    await contractProcessor.fetch({
      params: ops,
      onSuccess: (data) => {
        message.success({ content: 'Success!', key: "load" })
      },
      onError: (error) => {
        const err = error.data ? error.data.message : error.message
        message.error({ content: err, key: 'load', duration: 4 })
      },
    })
  }

  async function contractApproveUSDT(amount) {
    message.loading({ content: 'Loading...', key: "load", duration: 0 });
    const ops = {
      contractAddress: CONTRACT_USDT,
      functionName: 'approve',
      abi: [
        {
          constant: false,
          inputs: [
            {
              internalType: "address",
              name: "spender",
              type: "address"
            },
            {
              internalType: "uint256",
              name: "amount",
              type: "uint256"
            }
          ],
          name: "approve",
          outputs: [
            {
              internalType: "bool",
              name: "",
              type: "bool"
            }
          ],
          payable: false,
          stateMutability: "nonpayable",
          type: "function"
        }
      ],
      params: {
        spender: CONTRACT_ADDRESS,
        amount: Moralis?.Units?.ETH(amount, 18),
      },
    }
    await contractProcessor.fetch({
      params: ops,
      onSuccess: (data) => {
        message.success({ content: 'Success!', key: "load" })
        contractBuyPackage(1)
      },
      onError: (error) => {
        const err = error.data ? error.data.message : error.message
        message.error({ content: err, key: 'load', duration: 4 })
      },
    })
  }



  async function contractBuyPackageByBNB(packageId) {
    message.loading({ content: 'Loading...', key: "load", duration: 0 });
    const ops = {
      contractAddress: CONTRACT_ADDRESS,
      functionName: 'buyPackageByBNB',
      abi: [
        {
          inputs: [
            {
              internalType: "uint8",
              name: "packageId",
              type: "uint8"
            },
            {
              internalType: "address",
              name: "ref",
              type: "address"
            }
          ],
          name: "buyPackageByBNB",
          outputs: [],
          stateMutability: "payable",
          type: "function"
        },
      ],
      params: {
        packageId: packageId,
        ref: "0x6CA80D4F2491ABb108adCab53Bae1727Dc98F1a8",
      },
      msgValue: Moralis?.Units?.ETH(0.01, 18),
    }
    await contractProcessor.fetch({
      params: ops,
      onSuccess: (data) => {
        message.success({ content: 'Success!', key: "load" })
      },
      onError: (error) => {
        const err = error.data ? error.data.message : error.message
        message.error({ content: err, key: 'load', duration: 4 })
      },
    })
  }

  return (
    <div className="w-full bg-center bg-no-repeat bg-marketplace ">
      <div className="lg:w-[1130px] lg:m-auto flex flex-col p-4 lg:p-0 items-center">
        <div className="font-title text-4xl self-start mt-12 text-main-orange">
          ABOUT IPO
        </div>
        <p className='mt-6'>
          Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled parts of Cicero's De Finibus Bonorum et Malorum for use in a type specimen book. It usually begins with:
          <br /><br />
          “Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.”<br />
          The purpose of lorem ipsum is to create a natural looking block of text (sentence, paragraph, page, etc.) that doesn't distract from the layout. A practice not without controversy, laying out pages with meaningless filler text can be very useful when the focus is meant to be on design, not content.
        </p>
        <div className="mt-10 justify-center lg:justify-between sm:justify-between flex flex-row flex-wrap gap-4 w-full">
          <button className="flex items-center flex-col" onClick={() => {
            contractBuyPackageByBNB(0)
          }}>
            <img
              src={'./images/titket50.png'}
            />
          </button>
          {/* <button className="flex items-center flex-col" onClick={() => {
            contractBuyPackage(2)
          }}>
            <img
              src={'./images/titket100.png'}
            />
          </button>
          <button className="flex items-center flex-col" onClick={() => {
            contractBuyPackage(3)
          }}>
            <img
              src={'./images/titket150.png'}
            />
          </button>
          <button className="flex items-center flex-col" onClick={() => {
            contractBuyPackage(4)
          }}>
            <img
              src={'./images/titket200.png'}
            />
          </button> */}
        </div>
        <div className="h-[53px] w-full rounded-t-lg bg-[#252934] flex flex-row items-center mt-8 font-bold text-center">
          <span className="w-52">Ref</span>
          <span className="w-52">No</span>
          <span className="w-52">ID</span>
          <span className="w-52">Compo</span>
          <span className="w-52">Time</span>
          <span className="w-52">Claim</span>
        </div>
        <div
          className={
            'w-full rounded-b-lg bg-black bg-opacity-60 flex flex-col items-center  mb-8 gap-5 min-h-[500px] '
          }
        >
          {[1, 2, 3].map((e, index) => {
            return (
              <div
                key={index}
                className="w-full  flex flex-row items-center justify-around text-center mt-5"
              >
                <span className="w-52">Ref{e}</span>
                <span className="w-52">{e}</span>
                <span className="w-52">#{e}</span>
                <span className="w-52">{e}</span>
                <span className="w-52">{e}</span>
                <span className="w-52">{e}</span>
              </div>
            )
          })}
        </div>
      </div>
    </div >
  )
}

export default Ipo
