import React, { useState, useEffect } from 'react'
import { useMoralis, useMoralisWeb3Api, useWeb3ExecuteFunction } from 'react-moralis'
import APIService from "./service/APIService"
import { useMoralisDapp } from './providers/MoralisDappProvider/MoralisDappProvider'
import { InputNumber, message, Pagination } from 'antd'
import { priceFormat } from './helpers/formatters'
const TOKEN_ADDRESS = window.localStorage.getItem('TOKEN_ADDRESS')
const ADMIN_ADDRESS = window.localStorage.getItem('ADMIN_ADDRESS')

const TokenTransfer = () => {
  const { walletAddress } = useMoralisDapp()
  const [listHero, setListHero] = useState([])
  const [balance, setBalance] = useState(0)
  const [coinInGame, setCoinInGame] = useState(0)
  const [indexHeroToken, setIndexHeroToken] = useState(0)
  const [swapConvert, setSwapConvert] = useState(true) //true TOKEN TO BALL, false BALL To TOKEn
  const { Moralis } = useMoralis()
  const Web3Api = useMoralisWeb3Api();
  const [mountConvert, setMountConvert] = useState(0)
  const contractProcessor = useWeb3ExecuteFunction()

  useEffect(() => {
    if (!walletAddress) return
    getInventory()
    fetchNativeBalance()
  }, [walletAddress])



  async function getInventory() {
    const currentUser = await Moralis.User.current()
    let sessionToken = currentUser.attributes.sessionToken
    const headers = {
      Authorization: 'Bearer ' + sessionToken,
    }
    const response = await APIService.getInventoryHero(0, 100, "HERO", headers)
    setListHero(response.nfts)
    setCoinInGame(response.nfts[indexHeroToken].totalCoin)
    if (response.nfts[indexHeroToken].tokenId == 0) {
      message.error({ content: 'Hero is Alpha Test', key: 'load', duration: 4 })
    }
  }

  async function contractGetInfo(amount) {
    message.loading({ content: 'Loading...', key: "load", duration: 0 });
    const ops = {
      contractAddress: TOKEN_ADDRESS,
      functionName: 'transfer',
      abi: [
        {
          inputs: [
            {
              internalType: "address",
              name: "to",
              type: "address"
            },
            {
              internalType: "uint256",
              name: "amount",
              type: "uint256"
            }
          ],
          name: "transfer",
          outputs: [
            {
              internalType: "bool",
              name: "",
              type: "bool"
            }
          ],
          stateMutability: "nonpayable",
          type: "function"
        }
      ],
      params: {
        to: ADMIN_ADDRESS,
        amount: Moralis?.Units?.ETH(amount, 18),
      },
    }
    await contractProcessor.fetch({
      params: ops,
      onSuccess: (data) => {
        message.success({ content: 'Success!', key: "load" })
        setMountConvert(0)
        fetchNativeBalance()
      },
      onError: (error) => {
        message.error({ content: error.message, key: 'load', duration: 4 })
      },
    })
  }

  const fetchNativeBalance = async () => {
    const options = {
      chain: "bsc testnet",
      address: walletAddress,
      // to_block: "1234",
    };
    const bscBalance = await Web3Api.account.getTokenBalances(options);
    var newArray = bscBalance.filter((e) => { return e.symbol == "GBS" })
    let _balance = Moralis?.Units?.FromWei(newArray[0].balance)
    setBalance(_balance)
  };


  async function convertToCoin(idHero, amount) {
    if (amount == 0 || idHero == 0) {
      return
    }
    const currentUser = await Moralis.User.current()
    let sessionToken = currentUser.attributes.sessionToken
    const headers = {
      Authorization: 'Bearer ' + sessionToken,
    }
    const response = await APIService.convertToCoin(
      idHero, amount,
      headers
    )
    if (response) {
      contractGetInfo(amount)
    }
  }

  async function convertToToken(idHero, amount) {
    if (amount == 0 || idHero == 0) {
      return
    }
    const currentUser = await Moralis.User.current()
    let sessionToken = currentUser.attributes.sessionToken
    const headers = {
      Authorization: 'Bearer ' + sessionToken,
    }
    const response = await APIService.convertToToken(
      idHero, amount,
      headers
    )
    if (response) {
      setMountConvert(0)
      getInventory()
    }
  }


  return (
    <div className="lg:w-[1140px] lg:m-auto flex flex-col items-center p-4 lg:p-0">
      <div className="font-title text-4xl text-white self-start mt-12">
        TOKEN TRANSFER
      </div>
      <div className="lg:w-[400px] sm:w-[400px] lg:m-auto sm:m-auto flex flex-col mx-2 w-full">
        <div className="flex flex-row gap-4 mt-2 items-end sm:mt-12">
          <div className="w-full">
            <p>Choose Hero NFT</p>
            <select
              className="w-full bg-[#262e36] rounded-md mt-1 h-9 px-1"
              value={indexHeroToken}
              onChange={(e) => {
                setIndexHeroToken(e.target.value)
                setCoinInGame(listHero[e.target.value].totalCoin)
              }}
            >
              {listHero.map((item, index) => {
                return (
                  <option key={index} value={index}>{item.name}</option>
                )
              })}
            </select>
          </div>

        </div>
        <div className="flex flex-row gap-4 mt-7">
          <div className="flex flex-row w-full h-9 bg-[#262e36] rounded-md items-center px-2">
            <img alt="" className="h-5 w-5" src="./images/gbs.png" />
            <p className="ml-2 w-full font-semibold">{priceFormat(parseFloat(balance).toFixed(2))}</p>
            <p className="text-[#9DE40B] font-semibold">GOBALL</p>
          </div>
          <div className="flex flex-row w-full h-9 bg-[#262e36] rounded-md items-center px-2">
            <img alt="" className="h-6 w-6" src="./images/ic_coin.png" />
            <p className="ml-2 w-full font-semibold">{priceFormat(coinInGame)}</p>
            <p className="text-[#1074DA] font-semibold">BALL</p>
          </div>
        </div>
        {swapConvert ? <div className='flex flex-col items-center'>
          <p className="text-lg	mt-7 flex-row flex-1 text-center sm:px-0 px-6">
            CONVERT <span className="text-[#9DE40B] mx-1">GOBALL TOKEN</span> TO <span className="text-[#1074DA] mx-1">BALL INGAME</span>
          </p>
          <div className='flex flex-row items-center'>
            <p className="text-2xl mt-1 font-bold text-[#9DE40B] mx-1">1</p>
            <img alt="" className="h-5 w-5  mx-1" src="./images/gbs.png" />
            <p className="text-2xl mt-1 font-bold text-[#9DE40B]  mx-1">=</p>
            <p className="text-2xl mt-1 font-bold text-[#9DE40B]  mx-1">1</p>
            <img alt="" className="h-6 w-6  mx-1" src="./images/ic_coin.png" />
          </div>

          <div className="flex flex-row w-full h-9 bg-[#262e36] mt-5 rounded-md items-center px-2">
            <img alt="" className="h-5 w-5" src="./images/gbs.png" />
            <InputNumber
              className="w-full"
              controls={false}
              type="text"
              name="HeroID"
              max={balance}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              value={mountConvert}
              onChange={(e) => {
                setMountConvert(e)
              }}
            />
          </div>
          <button onClick={() => {
            setSwapConvert(!swapConvert)
          }} className="button-scale">
            <img
              alt=""
              className="h-5 w-5 m-auto mt-4 "
              src="./images/ic_convert.png"
            />
          </button>

          <div className="flex flex-row w-full h-9 bg-[#262e36] rounded-md items-center px-2 mt-4">
            <img alt="" className="h-6 w-6" src="./images/ic_coin.png" />
            <InputNumber
              className="w-full"
              controls={false}
              type="text"
              name="HeroID"
              max={balance}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              value={mountConvert}
              onChange={(e) => {
                setMountConvert(e)
              }}
            />
          </div>
          <button className="w-[170px] bg-[#9DE40B] m-auto rounded-full mt-7 h-9 flex items-center justify-center  text-black button-scale" onClick={() => {
            convertToCoin(listHero[indexHeroToken].itemId, mountConvert)
          }}>
            <p className="font-bold">CONVERT</p>
          </button>
        </div> :
          <div className='flex flex-col items-center'>
            <p className="text-lg	mt-7 flex flex-row">
              CONVERT <p className="text-[#1074DA] mx-1">BALL INGAME</p> TO  <p className="text-[#9DE40B] mx-1">GOBALL TOKEN</p>
            </p>
            <div className='flex flex-row items-center'>
              <p className="text-2xl mt-1 font-bold text-[#9DE40B] mx-1">1</p>
              <img alt="" className="h-5 w-5  mx-1" src="./images/ic_coin.png" />
              <p className="text-2xl mt-1 font-bold text-[#9DE40B]  mx-1">=</p>
              <p className="text-2xl mt-1 font-bold text-[#9DE40B]  mx-1">1</p>
              <img alt="" className="h-5 w-5  mx-1" src="./images/gbs.png" />
            </div>
            <div className="flex flex-row w-full h-9 bg-[#262e36] mt-5 rounded-md items-center px-2">
              <img alt="" className="h-5 w-5" src="./images/ic_coin.png" />
              <InputNumber
                className="w-full"
                controls={false}
                type="text"
                name="HeroID"
                max={coinInGame}
                value={mountConvert}
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                onChange={(e) => {
                  setMountConvert(e)
                }}
              />
            </div>

            <button onClick={() => {
              setSwapConvert(!swapConvert)
            }} className='button-scale'>
              <img
                alt=""
                className="h-5 w-5 m-auto mt-4 "
                src="./images/ic_convert.png"
              />
            </button>
            <div className="flex flex-row w-full h-9 bg-[#262e36] mt-4 rounded-md items-center px-2">
              <img alt="" className="h-5 w-5" src="./images/gbs.png" />
              <InputNumber
                className="w-full"
                controls={false}
                type="text"
                name="HeroID"
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                max={coinInGame}
                value={mountConvert}
                onChange={(e) => {
                  setMountConvert(e)
                }}
              />
            </div>

            <button className="w-[170px] bg-[#9DE40B] m-auto rounded-full mt-7 h-9 flex items-center justify-center  text-black button-scale" onClick={() => {
              convertToToken(listHero[indexHeroToken].itemId, mountConvert)
            }}>
              <p className="font-bold">CONVERT</p>
            </button>
          </div>}
      </div>
    </div>
  )
}

export default TokenTransfer
