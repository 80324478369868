import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import APIService from "./service/APIService"
import { useMoralisDapp } from './providers/MoralisDappProvider/MoralisDappProvider'
import { message } from 'antd'

async function getRef(owner, referer) {
  try {
    if (!owner) {
      message.error({ content: 'Please connect your wallet', key: "load" })
      return
    }
    const response = await APIService.getRef(owner, referer)
    return response
  } catch (error) {
    console.error(error)
  }
}


const Ref = () => {
  let { id } = useParams()
  const { walletAddress } = useMoralisDapp()
  return (
    <div className="w-full bg-center bg-cover  bg-no-repeat bg-marketplace ">
      <div className="lg:w-[1140px] lg:m-auto flex flex-col p-4 lg:p-0 items-center">
        <div className="font-title text-4xl text-white self-start mt-12">
        FINAL TEST EVENT
        </div>
        <div className='lg:w-[730px] lg:h-[600px] mt-14 flex flex-col items-center '>
        <p className=' '>
          Welcome to GoBall's final test version, the leading game of Skill to Earn. <br />
          We desire to create a platform not just for NFT collection, but also a platform in which users can equip their NFT players with beautiful hand-crafted items, and experience the thrill of fast-paced multiplayer soccer matches!
        </p>
        <div className='lg:w-[540px] bg-[#321C71] flex lg:flex-row flex-col py-5 px-4 lg:px-0 rounded-2xl mt-[60px]'>
          <div className='lg:w-1/2 w-full relative'>
            <img src="../images/img-ref.png" className='absolute lg:w-[85%] w-2/3 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2' />
          </div>
          <div className='w-full lg:w-1/2 flex flex-col mt-20 lg:mt-0'>
            <p className='text-lg font-black'>GET FREE NFT</p>
            <button onClick={async () => {
              const ref = await getRef(walletAddress, id)
              if (ref.id) {
                message.success({ content: 'Success!', key: "load" })
                setTimeout(() => {
                  window.open("https://game.goball.io/index.html?time=" + new Date().getTime());
                }, 3000);
              }
            }} className='relative mt-4'>
              <img src="../images/btn-ref.png" className='rounded-xl' />
              <p className='absolute top-1/2 left-2/3 transform -translate-x-2/3 -translate-y-1/2 text-3xl font-black'>RECEIVE</p>
            </button>
          </div>
        </div>
        </div>
        
      </div>
    </div >
  )
}

export default Ref
