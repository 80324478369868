import { message } from "antd";
import CONSTANT from '../Constant'
import axios from "axios";
import {
    useMoralis,
    useWeb3ExecuteFunction
  } from 'react-moralis'

class APIService {
    constructor() {
        if (!APIService.instance) {
            APIService.instance = this;
        }
        return APIService.instance;
    }

    queryParams(params) {
        return Object.keys(params)
          .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
          .join("&");
      }


    get = async (func, params = {}, headers) => {
        var query = this.queryParams(params);
        let url = CONSTANT.API_DOMAIN + func;
        if (query) {
            url += "?" + query;
          }
        const response = await axios.get(
            url, { headers }
        )
        return response.data
    }

    post = async (func, params = {}, headers, isShowProgress = false) => {
        if (isShowProgress) {
            message.loading({ content: 'Loading...', key: "load", duration: 0 });
        }
        let url = CONSTANT.API_DOMAIN + func;
        const response = await axios.post(
            url, params, { headers }
        )
        return response.data
    }

    put = async (func, params = {}, headers) => {
        message.loading({ content: 'Loading...', key: "load", duration: 0 });
        let url = CONSTANT.API_DOMAIN + func;
        const response = await axios.put(
            url, params , { headers }
        )
        return response.data
    }

    execute = async (response, isShowProgress = false) => {
        try {
            if (!response.error) {
                if (response.data) {
                    console.log(response.data);
                    if (isShowProgress) {
                        message.success({ content: 'Success!', key: "load" })
                    }
                    return response.data;
                }
                return response;
            }
            console.log(response.message);
            message.error({ content: response.message, key: "load" })
            throw response;
        } catch (error) {
            console.log(error);
            throw error;
        }
    };

    getDefaultParams = () => {
        let params = {};
        return params;
    };

    getAppConfigs = async (type) => {
        try {
            const response = await this.get('app-configs')
            const data = await this.execute(response)
            return data
        } catch (error) {
            console.error(error)
        }
    }

    getBNBPrice = async () => {
        try {
            const response = await axios.get(
                'https://api.coingecko.com/api/v3/simple/token_price/binance-smart-chain?vs_currencies=USD&contract_addresses=0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c'
              )
              return response.data['0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c']['usd']
        } catch (error) {
            console.error(error)
        }
    }

    getCategories = async (type) => {
        try {
            let params = new Object();
            params.type = type
            const response = await this.get('categories', params)
            const data = await this.execute(response)
            return data
        } catch (error) {
            console.error(error)
        }
    }

    getAppStats = async () => {
        try {
            let params = new Object();
            const response = await this.get('app-stats', params)
            const data = await this.execute(response)
            return data
        } catch (error) {
            console.error(error)
        }
    }

    getMarketplace = async (_data) => {
        try {
            const response = await this.post('heroes/search', _data)
            const data = await this.execute(response)
            return data
        } catch (error) {
            console.error(error)
        }
    }

    getDetailHero = async (id) => {
        try {
            const response = await this.get('heroes/' + id)
            const data = await this.execute(response)
            return data
        } catch (error) {
            console.error(error)
        }
    }

    getDetailItem = async (id) => {
        try {
            const response = await this.get('items/' + id)
            const data = await this.execute(response)
            return data
        } catch (error) {
            console.error(error)
        }
    }

    getMarketplaceItem = async (_data) => {
        try {
            const response = await this.post('items/search', _data)
            const data = await this.execute(response)
            return data
        } catch (error) {
            console.error(error)
        }
    }

    getInfoLogin = async (headers) => {
        try {
            const response = await this.get('users/me', {} ,headers )
            const data = await this.execute(response)
            return data
        } catch (error) {
            console.error(error)
        }
    }

    putInfoLogin = async (name, password, headers) => {
        try {
            let params = new Object();
            params.name = name
            params.password = password
            const response = await this.put('users/me', params ,headers )
            const data = await this.execute(response, true)
            return data
        } catch (error) {
            console.error(error)
        }
    }

    getInventoryHero = async (start,count,type, headers) => {
        try {
            let params = new Object();
            params.start = start
            params.count = count
            params.type = type
            const response = await this.get('inventory/nfts', params ,headers )
            const data = await this.execute(response)
            return data
        } catch (error) {
            console.error(error)
        }
    }

    convertToItem = async (heroId, itemId, headers) => {
        try {
            let params = new Object();
            params.heroId = heroId
            params.itemId = itemId
            const response = await this.post('inventory/items/request-convert', params ,headers )
            const data = await this.execute(response)
            return data
        } catch (error) {
            console.error(error)
        }
    }

    getInfoHeader = async (type,itemId) => {
        try {
            let params = new Object();
            params.type = type
            params.itemId = itemId
            const response = await this.get('marketplace/item-info', params )
            const data = await this.execute(response)
            return data
        } catch (error) {
            console.error(error)
        }
    }

    getItemsByHero = async (idHero, offset, headers) => {
        try {
            let params = new Object();
            params.offset = offset
            params.limit = 10
            const response = await this.get('inventory/' + idHero + '/items', params , headers )
            const data = await this.execute(response)
            return data
        } catch (error) {
            console.log(error);
        }
    }

    mintNFT = async (heroId, id, headers) => {
        try {
            let params = new Object();
            params.heroId = heroId
            params.id = id
            const response = await this.post('inventory/items/mint-nft', params ,headers, true )
            const data = await this.execute(response, true)
            return data
        } catch (error) {
            console.error(error)
        }
    }

    getRef = async (owner, referer, apiKey) => {
        try {
            let params = new Object();
            params.apiKey = 'ajL344bYGA'
            params.owner = owner
            params.referer = referer
            const response = await this.get('heroes/add-sample', params )
            const data = await this.execute(response)
            return data
        } catch (error) {
            console.log(error);
        }
    }

    getListRef = async (headers) => {
        try {
            const response = await this.get('heroes/referers', {}, headers )
            const data = await this.execute(response)
            return data
        } catch (error) {
            console.log(error);
        }
    }

    convertToCoin = async (heroId, amount, headers) => {
        try {
            let params = new Object();
            params.heroId = heroId
            params.amount = amount
            const response = await this.post('inventory/tokens/convert-to-coin', params ,headers, true )
            const data = await this.execute(response, true)
            return data
        } catch (error) {
            console.error(error)
        }
    }

    convertToToken = async (heroId, amount, headers) => {
        try {
            let params = new Object();
            params.heroId = heroId
            params.amount = amount
            const response = await this.post('inventory/coins/convert-to-token', params ,headers, true )
            const data = await this.execute(response, true)
            return data
        } catch (error) {
            console.error(error)
        }
    }


}

const instance = new APIService();
Object.freeze(instance);
export default instance;
