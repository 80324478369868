import React, { useState, useEffect } from 'react'
import { useMoralis, useMoralisWeb3Api, useWeb3ExecuteFunction } from 'react-moralis'
import APIService from "./service/APIService"
import { useMoralisDapp } from './providers/MoralisDappProvider/MoralisDappProvider'
import { InputNumber, message, Pagination } from 'antd'

const FACTOR = 10
const TOKEN_ADDRESS = window.localStorage.getItem('TOKEN_ADDRESS')
const ADMIN_ADDRESS = window.localStorage.getItem('ADMIN_ADDRESS')

const Ingame = () => {
  const { walletAddress } = useMoralisDapp()
  const [listHero, setListHero] = useState([])
  const [listItem, setListItem] = useState([])
  const [type, setType] = useState('TOKEN')
  const [balance, setBalance] = useState(0)
  const [coinInGame, setCoinInGame] = useState(0)
  const [indexHeroToken, setIndexHeroToken] = useState(0)
  const [indexHeroItems, setIndexHeroItems] = useState(0)
  const [swapConvert, setSwapConvert] = useState(true) //true TOKEN TO BALL, false BALL To TOKEn
  const { Moralis } = useMoralis()
  const Web3Api = useMoralisWeb3Api();
  const [mountConvert, setMountConvert] = useState(0)
  const contractProcessor = useWeb3ExecuteFunction()
  const [currentPage, setCurrentPage] = useState(1)
  const [totalItem, setTotalItem] = useState(0)

  useEffect(() => {
    getInventory()
    fetchNativeBalance()
  }, [walletAddress])

  useEffect(() => {
    if (listHero.length > 0  && listHero[indexHeroItems].itemId != 0) {
      getListItemByHero(listHero[indexHeroItems].itemId)
    }
  }, [currentPage])


  async function getInventory() {
    const currentUser = await Moralis.User.current()
    let sessionToken = currentUser.attributes.sessionToken
    const headers = {
      Authorization: 'Bearer ' + sessionToken,
    }
    const response = await APIService.getInventoryHero(0, 100, "HERO", headers)
    setListHero(response.nfts)
    setCoinInGame(response.nfts[indexHeroToken].totalCoin)
    if (response.nfts[indexHeroToken].tokenId == 0) {
      message.error({ content: 'Hero is Alpha Test', key: 'load', duration: 4 })
    }
  }

  async function contractGetInfo(amount) {
    message.loading({ content: 'Loading...', key: "load", duration: 0 });
    const ops = {
      contractAddress: TOKEN_ADDRESS,
      functionName: 'transfer',
      abi: [
        {
          inputs: [
            {
              internalType: "address",
              name: "to",
              type: "address"
            },
            {
              internalType: "uint256",
              name: "amount",
              type: "uint256"
            }
          ],
          name: "transfer",
          outputs: [
            {
              internalType: "bool",
              name: "",
              type: "bool"
            }
          ],
          stateMutability: "nonpayable",
          type: "function"
        }
      ],
      params: {
        to: ADMIN_ADDRESS,
        amount: Moralis?.Units?.ETH(amount, 18),
      },
    }
    await contractProcessor.fetch({
      params: ops,
      onSuccess: (data) => {
        message.success({ content: 'Success!', key: "load" })
        setMountConvert(0)
        fetchNativeBalance()
      },
      onError: (error) => {
        message.error({ content: error.message, key: 'load', duration: 4 })
      },
    })
  }

  const fetchNativeBalance = async () => {
    const options = {
      chain: "bsc testnet",
      address: walletAddress,
      // to_block: "1234",
    };
    const bscBalance = await Web3Api.account.getTokenBalances(options);
    var newArray = bscBalance.filter((e) => { return e.symbol == "GBS" })
    let _balance = Moralis?.Units?.FromWei(newArray[0].balance)
    setBalance(_balance)
  };

  async function getListItemByHero(idHero) {
    const _offset = (currentPage - 1) * 10
    const currentUser = await Moralis.User.current()
    let sessionToken = currentUser.attributes.sessionToken
    const headers = {
      Authorization: 'Bearer ' + sessionToken,
    }
    const response = await APIService.getItemsByHero(idHero, _offset, headers)
    setListItem(response.items)
    setTotalItem(response.total)
  }

  async function mintNFT(idHero, idItem) {
    const currentUser = await Moralis.User.current()
    let sessionToken = currentUser.attributes.sessionToken
    const headers = {
      Authorization: 'Bearer ' + sessionToken,
    }
    const response = await APIService.mintNFT(
      idHero, idItem,
      headers
    )
    if (response) {
      getListItemByHero(idHero)
    }
    // setListHero(response.data.data.nfts)
  }

  async function convertToCoin(idHero, amount) {
    if (amount == 0 || idHero == 0) {
      return
    }
    const currentUser = await Moralis.User.current()
    let sessionToken = currentUser.attributes.sessionToken
    const headers = {
      Authorization: 'Bearer ' + sessionToken,
    }
    const response = await APIService.convertToCoin(
      idHero, amount,
      headers
    )
    if (response) {
      contractGetInfo(amount)
    }
  }

  async function convertToToken(idHero, amount) {
    if (amount == 0 || idHero == 0) {
      return
    }
    const currentUser = await Moralis.User.current()
    let sessionToken = currentUser.attributes.sessionToken
    const headers = {
      Authorization: 'Bearer ' + sessionToken,
    }
    const response = await APIService.convertToToken(
      idHero, amount,
      headers
    )
    if (response) {
      setMountConvert(0)
      getInventory()
    }
  }

  const switchRender = () => {
    switch (type) {
      case 'TOKEN':
        return (<div className="lg:w-[400px] sm:w-[400px] lg:m-auto sm:m-auto flex flex-col mx-2">
          <div className="flex flex-row gap-4 mt-2 items-end">
            <div className="w-full">
              <p>Choose Hero NFT</p>
              <select
                className="w-full bg-[#262e36] rounded-md mt-1 h-9 px-1"
                value={indexHeroToken}
                onChange={(e) => {
                  setIndexHeroToken(e.target.value)
                  setCoinInGame(listHero[e.target.value].totalCoin)
                }}
              >
                {listHero.map((item, index) => {
                  return (
                    <option value={index}>{item.name}</option>
                  )
                })}
              </select>
            </div>
            
          </div>
          <div className="flex flex-row gap-4 mt-7">
            <div className="flex flex-row w-full h-9 bg-[#262e36] rounded-md items-center px-2">
              <img alt="" className="h-5 w-5" src="./images/gbs.png" />
              <p className="ml-2 w-full font-semibold">{parseFloat(balance).toFixed(2)}</p>
              <p className="text-[#9DE40B] font-semibold">GOBALL</p>
            </div>
            <div className="flex flex-row w-full h-9 bg-[#262e36] rounded-md items-center px-2">
              <img alt="" className="h-5 w-5" src="./images/ic_coin.png" />
              <p className="ml-2 w-full font-semibold">{coinInGame}</p>
              <p className="text-[#1074DA] font-semibold">BALL</p>
            </div>
          </div>
          {swapConvert ? <div className='flex flex-col items-center'>
            <p className="ext-lg	mt-7">
              CONVERT GOBALL TOKEN TO BALL INGAME
            </p>
            <div className='flex flex-row items-center'>
              <p className="text-2xl mt-1 font-bold text-[#9DE40B] mx-1">1</p>
              <img alt="" className="h-5 w-5  mx-1" src="./images/gbs.png" />
              <p className="text-2xl mt-1 font-bold text-[#9DE40B]  mx-1">=</p>
              <p className="text-2xl mt-1 font-bold text-[#9DE40B]  mx-1">1</p>
              <img alt="" className="h-5 w-5  mx-1" src="./images/ic_coin.png" />
            </div>

            <div className="flex flex-row w-full h-9 bg-[#262e36] mt-5 rounded-md items-center px-2">
              <img alt="" className="h-5 w-5" src="./images/gbs.png" />
              <InputNumber
                className="w-full"
                controls={false}
                type="text"
                name="HeroID"
                max={balance}
                value={mountConvert}
                onChange={(e) => {
                  setMountConvert(e)
                }}
              />
            </div>
            <button onClick={() => {
              setSwapConvert(!swapConvert)
            }}>
              <img
                alt=""
                className="h-5 w-5 m-auto mt-4 "
                src="./images/ic_convert.png"
              />
            </button>

            <div className="flex flex-row w-full h-9 bg-[#262e36] rounded-md items-center px-2 mt-4">
              <img alt="" className="h-5 w-5" src="./images/ic_coin.png" />
              <InputNumber
                className="w-full"
                controls={false}
                type="text"
                name="HeroID"
                max={balance}
                value={mountConvert}
                onChange={(e) => {
                  setMountConvert(e)
                }}
              />
            </div>
            <button className="w-[170px] bg-[#9DE40B] m-auto rounded-full mt-7 h-9 flex items-center justify-center  text-black" onClick={() => {
              convertToCoin(listHero[indexHeroToken].itemId, mountConvert)
            }}>
              <p className="font-bold">CONVERT</p>
            </button>
          </div> :
            <div className='flex flex-col items-center'>
              <p className="ext-lg	mt-7">
                CONVERT BALL INGAME TO GOBALL TOKEN
              </p>
              <div className='flex flex-row items-center'>
                <p className="text-2xl mt-1 font-bold text-[#9DE40B] mx-1">1</p>
                <img alt="" className="h-5 w-5  mx-1" src="./images/ic_coin.png" />
                <p className="text-2xl mt-1 font-bold text-[#9DE40B]  mx-1">=</p>
                <p className="text-2xl mt-1 font-bold text-[#9DE40B]  mx-1">1</p>
                <img alt="" className="h-5 w-5  mx-1" src="./images/gbs.png" />
              </div>
              <div className="flex flex-row w-full h-9 bg-[#262e36] mt-5 rounded-md items-center px-2">
                <img alt="" className="h-5 w-5" src="./images/ic_coin.png" />
                <InputNumber
                  className="w-full"
                  controls={false}
                  type="text"
                  name="HeroID"
                  max={balance}
                  value={mountConvert}
                  onChange={(e) => {
                    setMountConvert(e)
                  }}
                />
              </div>

              <button onClick={() => {
                setSwapConvert(!swapConvert)
              }}>
                <img
                  alt=""
                  className="h-5 w-5 m-auto mt-4 "
                  src="./images/ic_convert.png"
                />
              </button>
              <div className="flex flex-row w-full h-9 bg-[#262e36] mt-4 rounded-md items-center px-2">
                <img alt="" className="h-5 w-5" src="./images/gbs.png" />
                <InputNumber
                  className="w-full"
                  controls={false}
                  type="text"
                  name="HeroID"
                  max={balance}
                  value={mountConvert}
                  onChange={(e) => {
                    setMountConvert(e)
                  }}
                />
              </div>

              <button className="w-[170px] bg-[#9DE40B] m-auto rounded-full mt-7 h-9 flex items-center justify-center  text-black" onClick={() => {
                convertToToken(listHero[indexHeroToken].itemId, mountConvert)
              }}>
                <p className="font-bold">CONVERT</p>
              </button>
            </div>}
        </div>)

        break;
      case 'ITEMS':
        return (
          <>
          <div className="flex flex-row gap-4 mt-2 items-end">
            <div className="w-[221px]">
              <p>Choose Hero NFT</p>
              <select
                className="w-full bg-[#262e36] rounded-md mt-1 h-9 px-1"
                value={indexHeroItems}
                onChange={(e) => {
                  console.log(e.target.value);
                  setIndexHeroItems(e.target.value)
                }}
              >
                {listHero.map((item, index) => {
                  return (
                    <option value={index}>{item.name}</option>
                  )
                })}
              </select>
            </div>
            <button onClick={() => {
              setCurrentPage(1)
              getListItemByHero(listHero[indexHeroItems].itemId)
            }} className="w-[143px] bg-[#9DE40B] rounded-full mt-1 h-9 flex items-center justify-center  text-black">
              <p className="font-extrabold">SYNC DATA</p>
            </button>
          </div>
            <div className="h-[53px] w-full rounded-t-lg bg-[#252934] flex flex-row items-center justify-around mt-8 font-bold text-center px-2 ">
              <span className="w-14 sm:w-36 lg:w-50">NO</span>
              <span className="w-20 sm:w-48 lg:w-80 flex seft-start sm:pl-12 ">ITEM NAME</span>
              <span className="w-20 sm:w-48 lg:w-80">Item code</span>
              <span className="w-14 sm:w-36 lg:w-50">Avatar</span>
              <span className="flex-grow min-w-[120px]"></span>
            </div>
            <div className="w-full overflow-auto min-h-[300px] rounded-b-lg bg-black bg-opacity-60 flex flex-col items-center mb-8 gap-5 pt-4 pb-4 px-2">
              {listItem.map((e, i) => {
                return (
                  <div
                    key={e.block_hash}
                    className="w-full  flex flex-row items-center justify-around text-center"
                  >
                    <span className="w-14 sm:w-36 lg:w-50">
                      {i + 1}
                    </span>
                    <div>
                      <span className="flex w-20 sm:w-48 lg:w-80 font-black uppercase seft-start sm:pl-12">
                        {e.name}
                      </span>
                    </div>
                    <span className="w-20 sm:w-48 lg:w-80">
                      #{e.id}
                    </span>
                    <span className="w-14 sm:w-36 lg:w-50">
                      <div className='flex bg-[#0E1527] h-[46px] w-[46px] m-auto items-center justify-center rounded-lg text-sm'>
                        <img
                          className='h-[30px]'
                          src={e.imageUrl}
                        />
                      </div>

                    </span>
                    <span className="flex-grow min-w-[120px]">
                      <button onClick={() => {
                        mintNFT(listHero[indexHeroItems].itemId, e.id)
                      }}>
                        <img
                          className='m-auto'
                          src="./images/btn_mintnft.png"
                        />
                      </button>

                    </span>

                  </div>
                )
              })}
            </div>
            <div className='flex flex-grow flex-col items-center'>
              <Pagination
                className=""
                current={currentPage}
                onChange={(page) => {
                  setCurrentPage(page)
                }}
                showSizeChanger={false}
                total={totalItem}
                hideOnSinglePage={false}
                pageSize={10}
              />
            </div>

          </>
        )

      default:
        break;
    }
  }

  return (
    <div className="lg:w-[1140px] lg:m-auto flex flex-col items-center p-4 lg:p-0">
      <div className="font-title text-4xl text-white self-start mt-12">
        INGAME
      </div>
      <div className="flex flex-row gap-4 self-end">
        <div
          onClick={() => {
            setType('TOKEN')
            // getNFT('HERO')
          }}
          className={
            'rounded-lg px-4 up h-8 text-center leading-8 cursor-pointer ' +
            (type === 'TOKEN'
              ? 'border border-[#9DE40B] text-[#9DE40B]'
              : 'border-0 bg-gray-600')
          }
        >
          TOKEN TRANSFER
        </div>
        <div
          onClick={() => {
            setType('ITEMS')
            // getNFT('ITEM')
          }}
          className={
            'rounded-lg px-4 h-8 text-center leading-8 cursor-pointer ' +
            (type === 'ITEMS'
              ? 'border border-[#9DE40B] text-[#9DE40B]'
              : 'border-0 bg-gray-600')
          }
        >
          ITEMS TRANSFER
        </div>
      </div>
      {switchRender()}
    </div>
  )
}

export default Ingame
