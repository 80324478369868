import { useState, useEffect } from 'react'
import {
  useMoralis,
  useWeb3ExecuteFunction
} from 'react-moralis'
import { Tooltip, Form, Input, message } from 'antd'
import CONSTANT from './Constant'
import { useMoralisDapp } from './providers/MoralisDappProvider/MoralisDappProvider'
import { useNavigate } from 'react-router-dom'
import Popup from "./components/Popup"
import APIService from "./service/APIService"
const NFTAdress = CONSTANT.NFTAddress
const ADMIN_ADDRESS = window.localStorage.getItem('ADMIN_ADDRESS')


const Inventory = () => {
  const navigate = useNavigate()
  const [type, setType] = useState('HERO')
  const { Moralis } = useMoralis()
  const [listInventory, setListInventory] = useState([])
  const [listItem, setListItem] = useState([])
  const [infoLogin, setInfoLogin] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [isModalVisible, setModalVisible] = useState(false)
  const [valueUsername, setValueUsername] = useState('')
  const [valuePassword, setValuePassword] = useState('')
  const [valueConfirmPassword, setValueConfirmPassword] = useState('')
  const [isConvertModalVisible, setIsConvertModalVisible] = useState(false)
  const [itemChose, setItemChose] = useState({})
  const [sortId, setSortId] = useState(0)
  const [listHero, setListHero] = useState([])
  const [listRef, setListRef] = useState([])
  const { marketAddress, contractABI, walletAddress } = useMoralisDapp()
  const contractABIJson = JSON.parse(contractABI)
  const contractProcessor = useWeb3ExecuteFunction()

  async function callContract() {
    const ops = {
      contractAddress: NFTAdress,
      functionName: 'transferFrom',
      abi: contractABIJson,
      params: {
        from: walletAddress,
        to: ADMIN_ADDRESS,
        tokenId: itemChose.tokenId,
      },
    }
    await contractProcessor.fetch({
      params: ops,
      onSuccess: () => {
        getInventory()
        message.success({ content: 'Success!', key: "load" })
      },
      onError: (error) => {
        const err = error.data ? error.data.message : error.message
        message.error({ content: err, key: "load" })
      },
    })
  }

  async function convertToItem() {
    setIsConvertModalVisible(false)
    message.loading({ content: 'Loading...', key: "load", duration: 0 });
    const currentUser = await Moralis.User.current()
    let sessionToken = currentUser.attributes.sessionToken
    const headers = {
      Authorization: 'Bearer ' + sessionToken,
    }
    const response = await APIService.convertToItem(
      sortId, itemChose.itemId, headers
    )
    if (response) {
      callContract()
    }
  }

  useEffect(() => {
    if (!walletAddress) return
    if (listHero.length == 0 || listItem.length == 0) {
      getInventory()
    }
  }, [walletAddress, type])

  async function getInventory() {
    setIsLoading(true)
    const currentUser = await Moralis.User.current()
    let sessionToken = currentUser.attributes.sessionToken
    const headers = {
      Authorization: 'Bearer ' + sessionToken,
    }
    const response = await APIService.getInventoryHero(0, 100, type, headers)
    if (type == 'HERO') {
      setListHero(response.nfts)
      setSortId(response.nfts[0].itemId)
    } else if (type == 'ITEM') {
      setListItem(response.nfts)
    }
    setIsLoading(false)
  }

  async function getListRef() {
    setIsLoading(true)
    const currentUser = await Moralis.User.current()
    let sessionToken = currentUser.attributes.sessionToken
    const headers = {
      Authorization: 'Bearer ' + sessionToken,
    }
    const response = await APIService.getListRef(headers)
    setListRef(response)
    setIsLoading(false)
  }

  const swithRender = () => {
    switch (type) {
      case 'HERO':
        return (
          <div
            className={
              'w-full rounded-b-lg bg-black bg-opacity-60 flex flex-col items-center  mb-8 gap-5 min-h-[500px] '
            }
          >
            {listHero.map((e) => {
              return (
                <div
                  key={e.itemId}
                  className="w-full  flex flex-row items-center justify-around text-center mt-5"
                >
                  <span className="w-24">#{e.itemId}</span>
                  <span className="flex flex-row w-40 items-center justify-center">
                    <img
                      src={e.image}
                      alt="avatar"
                      className="w-12 h-12"
                    />
                  </span>
                  <span className="flex-grow">{e.name}</span>
                  <span className="w-40 flex">
                    {!e.isOnSale ? (
                      <div className='flex flex-row w-full'>
                        <div
                          onClick={() => {
                            navigate(
                              '/heroes-detail/' +
                              e.itemId +
                              '/sell/' +
                              e.tokenId
                            )
                          }}
                          className="p-1 rounded-2xl bg-main-green-light text-black text-sm font-bold w-[100px] text-center cursor-pointer"
                        >
                          SELL
                        </div>

                      </div>
                    ) : (<div className="p-1 rounded-2xl text-white text-sm font-bold w-[100px] text-center">
                      ON MARKET
                    </div>)}
                  </span>
                </div>
              )
            })}
          </div>
        )
      case 'ITEM':
        return (
          <div
            className={
              'w-full rounded-b-lg bg-black bg-opacity-60 flex flex-col items-center  mb-8 gap-5 min-h-[500px] '
            }
          >
            {listItem.map((e) => {
              return (
                <div
                  key={e.itemId}
                  className="w-full  flex flex-row items-center justify-around text-center mt-5"
                >
                  <span className="w-24">#{e.itemId}</span>
                  <span className="flex flex-row w-40 items-center justify-center">
                    <img
                      src={e.imageUrl}
                      alt="avatar"
                      className="w-12 h-12"
                    />
                  </span>
                  <span className="flex-grow">{e.name}</span>
                  <span className="w-40 flex">
                    {!e.isOnSale ? (
                      <div className='flex flex-row w-full'>
                        <div
                          onClick={() => {
                            navigate(
                              '/items-detail/' + e.itemId + '/sell/' + e.tokenId
                            )

                          }}
                          className="p-1 rounded-2xl bg-main-green-light text-black text-sm font-bold w-[100px] text-center cursor-pointer"
                        >
                          SELL
                        </div>
                        <div
                          onClick={() => {
                            setItemChose(e)
                            setIsConvertModalVisible(true)
                          }}
                          className="ml-4 cursor-pointer"
                        >
                          <img src="/images/ic_convertgame.png" alt="" />
                        </div>

                      </div>
                    ) : (<div className="p-1 rounded-2xl text-white text-sm font-bold w-[100px] text-center">
                      ON MARKET
                    </div>)}
                  </span>
                </div>
              )
            })}
          </div>
        )
      default:

    }
  }


  return (
    <div className="w-full bg-cover bg-center bg-no-repeat ">
      <div className="lg:w-[1140px] lg:m-auto flex flex-col items-center p-4 lg:p-0">
        <div className="font-title text-4xl text-white self-start mt-12">
          INVENTORY
        </div>
        <div className="flex flex-row gap-4 mt-6 self-end">
          <div
            onClick={() => {
              setType('HERO')
              // getNFT('HERO')
            }}
            className={
              'rounded-lg w-24 h-8 text-center leading-8 cursor-pointer ' +
              (type === 'HERO'
                ? 'border border-[#9DE40B] text-[#9DE40B]'
                : 'border-0 bg-gray-600')
            }
          >
            HERO
          </div>
          <div
            onClick={() => {
              setType('ITEM')
              // getNFT('ITEM')
            }}
            className={
              'rounded-lg w-24 h-8 text-center leading-8 cursor-pointer ' +
              (type === 'ITEM'
                ? 'border border-[#9DE40B] text-[#9DE40B]'
                : 'border-0 bg-gray-600')
            }
          >
            ITEM
          </div>
        </div>
        {type == 'HERO' || type == 'ITEM' ? <div className="h-[53px] w-full rounded-t-lg bg-[#252934] flex flex-row items-center mt-4 font-bold text-center">
          <span className="w-24">No</span>
          <span className="w-40">Avatar</span>
          <span className="flex-grow">Name</span>
          <span className="w-40"></span>
        </div> : null}

        {isLoading ? (
          <div className="w-full text-center min-h-[500px] mt-10">
            Loading...
          </div>
        ) :
          swithRender()}
      </div>

      <Popup title="CONVERT GAME"
        visible={isConvertModalVisible}
        onOk={convertToItem}
        txtConfirm="CONVERT"
        onCancel={() => {
          setIsConvertModalVisible(false)
        }}>
        <div>
          <p className='text-white'>Choose Hero NFT</p>
          <select
            className="bg-[#13151D] w-[360px] text-white rounded-md mt-1 h-9 px-1"
            value={sortId}
            onChange={(e) => {
              setSortId(e.target.value)
            }}
          >
            {listHero.map((item) => {
              return (
                <option value={item.itemId} key={item.itemId}>{item.name}</option>
              )
            })}
          </select>
        </div>
      </Popup >
    </div >
  )
}

export default Inventory
