import { useState, useEffect } from 'react'
import {
  useMoralis,
  useWeb3ExecuteFunction
} from 'react-moralis'
import { Tooltip, Form, Input, message } from 'antd'
import CONSTANT from './Constant'
import { useMoralisDapp } from './providers/MoralisDappProvider/MoralisDappProvider'
import Popup from "./components/Popup"
import APIService from "./service/APIService"
const NFTAdress = CONSTANT.NFTAddress
const ADMIN_ADDRESS = window.localStorage.getItem('ADMIN_ADDRESS')


const LoginGame = () => {
  const { Moralis } = useMoralis()
  const [infoLogin, setInfoLogin] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [isModalVisible, setModalVisible] = useState(false)
  const [valueUsername, setValueUsername] = useState('')
  const [valuePassword, setValuePassword] = useState('')
  const [valueConfirmPassword, setValueConfirmPassword] = useState('')
  const { walletAddress } = useMoralisDapp()


  async function getInfoLogin() {
    setIsLoading(true)
    const currentUser = await Moralis.User.current()
    let sessionToken = currentUser.attributes.sessionToken
    const headers = {
      Authorization: 'Bearer ' + sessionToken,
    }
    const response = await APIService.getInfoLogin(headers)
    setInfoLogin(response)
    setValueUsername(response.name)
    setValuePassword("")
    setValueConfirmPassword("")
    setIsLoading(false)
  }

  async function putInfoLogin() {
    const currentUser = await Moralis.User.current()
    let sessionToken = currentUser.attributes.sessionToken
    let path = 'users/me'
    const headers = {
      Authorization: 'Bearer ' + sessionToken,
    }
    const response = await APIService.putInfoLogin(
      valueUsername, valuePassword, headers
    )
    getInfoLogin()
    setModalVisible(false)
  }

  useEffect(() => {
    if (walletAddress) {
      getInfoLogin()
    }
  }, [walletAddress])

  return (
    <div className="w-full bg-cover bg-center bg-no-repeat ">
      <div className="lg:w-[1140px] lg:m-auto flex flex-col items-center p-4 lg:p-0">
        <div className="font-title text-4xl text-white self-start mt-12">
          LOGIN GAME
        </div>
        <div className="h-[53px] w-full rounded-t-lg bg-[#252934] flex flex-row items-center mt-8 font-bold text-center">
          <span className="w-32 sm:w-60">Username</span>
          <span className="w-32 sm:w-60">Password</span>
          <span className="flex-grow"></span>
        </div>
        {isLoading ? (
          <div className="w-full text-center min-h-[500px] mt-10">
            Loading...
          </div>
        ) :
          <div
            className={
              'w-full rounded-b-lg bg-black bg-opacity-60 flex flex-col items-center  mb-8 gap-5 min-h-[500px] '
            }
          >
            <div className="w-full flex flex-row items-center justify-around text-center mt-5">
              <span className="flex flex-row w-32 sm:w-60 items-center justify-center">
                {infoLogin.name}
              </span>
              <span className="w-32 sm:w-60">
                {infoLogin.isSetPassword ? '*********' : null}
              </span>
              <span className="flex-grow flex justify-center">
                <button
                  onClick={() => {
                    setModalVisible(true)
                  }}
                  className="p-1 rounded-2xl bg-main-green-light text-black text-sm font-bold w-[100px] text-center cursor-pointer button-scale"
                >
                  EDIT
                </button>
              </span>
            </div>
          </div>}
      </div>
      <Popup title="EDIT LOGIN GAME"
        visible={isModalVisible}
        onOk={putInfoLogin}
        txtConfirm="EDIT"
        onCancel={() => {
          setModalVisible(false)
        }}>
        <Form>
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: 'Please input your Username!',
              },
              {
                min: 8,
                message: 'At least 8 characters!',
              },
              {
                pattern: new RegExp('^[a-zA-Z0-9]*$'),
                message: 'Without any special characters!',
              },
            ]}
          >
            <div className="textsell mt-2 flex-col">
              <span className='text-white'>Username</span>
              <Input
                type="text"
                value={valueUsername}
                onChange={(e) => {
                  setValueUsername(e.target.value)
                }}
              ></Input>
            </div>
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your Password!',
              },
              {
                min: 8,
                max: 32,
                message: 'At least 8 characters and up to 32!',
              },
              {
                pattern: new RegExp('^[a-zA-Z0-9]*$'),
                message: 'Without any special characters!',
              },
            ]}
          >
            <div className="textsell mt-2 flex-col">
              <span className='text-white'>Password</span>
              <Input.Password
                type="text"
                value={valuePassword}
                onChange={(e) => {
                  setValuePassword(e.target.value)
                }}
              ></Input.Password>
            </div>
          </Form.Item>
          <Form.Item
            name="confirm"
            dependencies={['password']}
            rules={[
              {
                required: true,
                message: 'Please confirm your password!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject(
                    new Error(
                      'The two passwords that you entered do not match!'
                    )
                  )
                },
              }),
            ]}
          >
            <div className="textsell mt-2">
              <span className='text-white'>Confirm Password</span>
              <Input.Password
                type="text"
                value={valueConfirmPassword}
                onChange={(e) => {
                  setValueConfirmPassword(e.target.value)
                }}
              ></Input.Password>
            </div>
          </Form.Item>
        </Form>
      </Popup>
    </div >
  )
}

export default LoginGame
