/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-undef */
import { useState, useEffect } from 'react'
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom'
import { useMoralis, useWeb3ExecuteFunction } from 'react-moralis'
import { getEllipsisTxt } from '../helpers/formatters'
import { Modal, Button, Input, Tooltip, Collapse, message } from 'antd'
import { useMoralisDapp } from '../providers/MoralisDappProvider/MoralisDappProvider'
import { CaretRightOutlined } from '@ant-design/icons';
import { createAnimationIcon } from '../components/Logo'
import CONSTANT from '../Constant'
import { Coin98Connector } from '../providers/Coin98Provider/Coin98Provider'
import Popup from './Popup'
import APIService from "./../service/APIService"

const { Panel } = Collapse;

const NFTAdress = CONSTANT.NFTAddress
const TOKEN_ADDRESS = window.localStorage.getItem('TOKEN_ADDRESS')
const TOKEN = '0x2339D8326ec793AF7B9e854776a58812430d3A62'
const Logo = createAnimationIcon({
  id: 'spine-logo',
  jsonURL: '/images/animation_logo.json',
  width: 190,
  height: 120,
})
const Play = createAnimationIcon({
  id: 'spine-play',
  jsonURL: '/images/animation_play.json',
  width: 317,
  height: 167,
  className:
    'absolute left-1/2 -translate-x-1/2 top-280 -translate-y-5  w-[125px] top-[140px] lg:w-317 lg:top-486',
  animScale: window.outerWidth > 500 ? 0.9 : 0.5,
})
const Hero = createAnimationIcon({
  id: 'spine-hero',
  jsonURL: '/images/animation_char2_skin.json',
  width: 420,
  height: 500,
  className:
    'm-auto mt-2 lg:mt-10 lg:absolute w-[156px] h-[170px] lg:h-[500px] lg:w-[420px] z-0 lg:right-[70px] hidden lg:block',
  animScale: 0.7,
})

async function getHeroDetail(id, type) {
  try {
    const response = await APIService.getInfoHeader(
      type,
      id
    )
    return response
  } catch (error) {
    console.error(error)
  }
}

async function getHeroInfo(id, type) {
  try {
    var response
    if (type === 'ITEM') {
      response = await APIService.getDetailItem(id)
    } else {
      response = await APIService.getDetailHero(id)
    }
    return response
  } catch (error) {
    console.error(error)
  }
}

const Header = (props) => {
  const navigate = useNavigate()
  const { authenticate, isAuthenticated, logout, Moralis, isWeb3Enabled } =
    useMoralis()
  const [loading, setLoading] = useState(false)
  const [sellPrice, setSellPrice] = useState(0)
  const [isShowMenu, setIsShowMenu] = useState(false)
  const [activeTab, setActiveTab] = useState("")
  const { pathname } = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  let { id, type, tokenId } = useParams()
  const [typeItem, setTypeItem] = useState('HERO')
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isSelectWalletModal, setIsSelectWalletModal] = useState(false)
  let background = 'bg-banner-home'
  switch (props.path) {
    case '/':
      background = 'bg-banner-home lg:h-864 h-[300px]'
      break
    case '/heroes':
      background = 'bg-banner-heroes lg:h-864 h-[300px]'
      break
    case '/items':
      background = 'bg-item-banner lg:h-864 h-[250px]'
      break
    case '/tokenomic':
      background = 'lg:bg-tokenomic-banner sm:bg-tokenomic-banner bg-tokenomic-mobile h-[300px] sm:h-[500px] lg:h-[660px]'
      break
    case '/heroes-detail':
      background = 'bg-contain bg-banner-heroes-detail lg:h-[772px] h-[490px]'
      break
    case '/items-detail':
      background = 'bg-item-detail-banner lg:h-[772px] h-[500px]'
      break
    case '/comingsoon':
      background = 'bg-banner-soon h-772'
      break
    case '/instruction':
      background =
        'bg-contain bg-banner-instruction-mobile sm:bg-banner-instruction lg:h-[1100px] sm:h-[600px] h-[400px]'
      break
    case '/termsofservices':
      background =
        'bg-contain bg-banner-instruction-mobile lg:bg-banner-instruction lg:h-[1100px] h-[400px]'
      break
    default:
      background = 'h-[60px] lg:h-[270px]'
      break
  }

  useEffect(() => {
    setHeroInfo(null)
    async function getHero() {
      if (id && id > 0 && props.path == '/heroes-detail' || props.path === '/items-detail') {
        var typeGet = "ITEM"
        if (props.path === '/heroes-detail') {
          typeGet = "HERO"
          setTypeItem('HERO')
        } else {
          setTypeItem('ITEM')
        }
        let hero = await getHeroDetail(id, typeGet)
        let info = await getHeroInfo(id, typeGet)
        setHeroInfo(info)
        if (type === 'buy') {
          setNftToBuy(hero.lists[0])
        }
      }
    }
    getHero()
  }, [id])

  async function connectWallet(connectorId) {
    try {
      await authenticate({ provider: connectorId })
      if (Moralis.connector == null) {
        alert('Please install wallet to your browser')
      }

      window.localStorage.setItem('connectorId', connectorId)
    } catch (e) {
      console.error(e)
    }
  }
  const contractProcessor = useWeb3ExecuteFunction()

  const [nftToBuy, setNftToBuy] = useState(null)
  const [heroInfo, setHeroInfo] = useState(null)
  const [isSellModalVisible, setIsSellModalVisible] = useState(false)

  const { marketAddress, contractABI, walletAddress } = useMoralisDapp()
  const contractABIJson = JSON.parse(contractABI)

  async function list() {
    setLoading(true)
    const p = sellPrice * ('1e' + 18)
    const ops = {
      contractAddress: marketAddress,
      functionName: 'createMarketItem',
      abi: contractABIJson,
      params: {
        nftContract: NFTAdress,
        tokenId: tokenId,
        salePrice: String(p),
      },
    }

    await contractProcessor.fetch({
      params: ops,
      onSuccess: () => {
        setLoading(false)
        succSell()
      },
      onError: (error) => {
        const err = error.data ? error.data.message : error.message
        message.error({ content: err, key: 'load', duration: 4 })
        setLoading(false)
      },
    })
  }

  async function approveAll() {
    setLoading(true)
    const ops = {
      contractAddress: NFTAdress,
      functionName: 'approve',
      abi: [
        {
          inputs: [
            {
              internalType: 'address',
              name: 'to',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'tokenId',
              type: 'uint256',
            },
          ],
          name: 'approve',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
      ],
      params: {
        to: marketAddress,
        tokenId: heroInfo.tokenId,
      },
    }

    await contractProcessor.fetch({
      params: ops,
      onSuccess: () => {
        list()
      },
      onError: (error) => {
        const err = error.data ? error.data.message : error.message
        message.error({ content: err, key: 'load', duration: 4 })
        setLoading(false)
      },
    })
  }

  async function sellNow() {
    setIsSellModalVisible(true)
  }
  async function sellHeroNow() {
    setIsSellModalVisible(false)
    await approveAll()
  }
  async function buyNow() {
    if (!walletAddress) {
      alert('Please connect your wallet')
      return
    }
    // navigate('/comingsoon')
    setLoading(true)
    const itemID = nftToBuy.marketItemId
    if (typeItem == 'HERO') {
      contractBuyHero()
    } else {
      contractApproveBuyItem()
    }
  }

  async function contractApproveBuyItem() {
    const tokenPrice = nftToBuy.salePrice
    const ops = {
      contractAddress: TOKEN_ADDRESS,
      functionName: 'approve',
      abi: [
        {
          inputs: [
            {
              internalType: 'address',
              name: 'spender',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'approve',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'nonpayable',
          type: 'function',
        },
      ],
      params: {
        spender: marketAddress,
        amount: tokenPrice,
      },
    }

    await contractProcessor.fetch({
      params: ops,
      onSuccess: () => {
        contractBuyItem()
      },
      onError: (error) => {
        const err = error.data ? error.data.message : error.message
        message.error({ content: err, key: 'load', duration: 4 })
        setLoading(false)
      },
    })
  }

  async function contractBuyItem() {
    const tokenPrice = nftToBuy.salePrice
    const ops = {
      contractAddress: marketAddress,
      functionName: 'buyItem',
      abi: [
        {
          inputs: [
            {
              internalType: 'address',
              name: '_nftContract',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: '_idInMarket',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: '_tokenId',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: '_amount',
              type: 'uint256',
            },
          ],
          name: 'buyItem',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
      ],
      params: {
        _nftContract: nftToBuy.nftContract,
        _idInMarket: nftToBuy.idInMarket,
        _tokenId: nftToBuy.tokenId,
        _amount: tokenPrice,
      },
    }

    await contractProcessor.fetch({
      params: ops,
      onSuccess: () => {
        setLoading(false)
        // setVisibility(false)
        // updateSoldMarketItem()
        succPurchase()
      },
      onError: (error) => {
        const err = error.data ? error.data.message : error.message
        message.error({ content: err, key: 'load', duration: 4 })
        setLoading(false)
      },
    })
  }

  async function contractBuyHero() {
    const tokenPrice = nftToBuy.salePrice
    const ops = {
      contractAddress: marketAddress,
      functionName: 'buyHero',
      abi: [
        {
          inputs: [
            {
              internalType: 'address',
              name: '_nftContract',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: '_idInMarket',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: '_tokenId',
              type: 'uint256',
            },
          ],
          name: 'buyHero',
          outputs: [],
          stateMutability: 'payable',
          type: 'function',
        },
      ],
      params: {
        _nftContract: nftToBuy.nftContract,
        _idInMarket: nftToBuy.idInMarket,
        _tokenId: nftToBuy.tokenId,
      },
      msgValue: tokenPrice,
    }

    await contractProcessor.fetch({
      params: ops,
      onSuccess: () => {
        setLoading(false)
        // setVisibility(false)
        // updateSoldMarketItem()
        succPurchase()
      },
      onError: (error) => {
        const err = error.data ? error.data.message : error.message
        message.error({ content: err, key: 'load', duration: 4 })
        setLoading(false)
      },
    })
  }

  function succSell() {
    let secondsToGo = 3
    const modal = Modal.success({
      title: 'Success!',
      content: `You have sold this NFT`,
    })
    setTimeout(() => {
      modal.destroy()
      navigate('/heroes')
    }, secondsToGo * 1000)
  }

  function succPurchase() {
    let secondsToGo = 3
    const modal = Modal.success({
      title: 'Success!',
      content: `You have purchased this NFT`,
    })
    setTimeout(() => {
      modal.destroy()
      navigate('/inventory')
    }, secondsToGo * 1000)
  }

  const onChangeCollapse = (key) => {
    setActiveTab(key)
  };

  return (
    <div className={'bg-cover bg-center bg-no-repeat ' + background}>
      {props.path === '/' ? <a href={"https://game.goball.io/index.html?time=" + new Date().getTime()} target="_blank"><Play /></a> : null}
      {props.path === '/instruction' ? <div className='absolute lg:left-64 sm:left-20 right-[-15px] top-280 w-[235px] top-[140px] lg:w-317 lg:top-344 sm:w-317 sm:top-284'>
        <a href="https://testflight.apple.com/join/88XWVeup" target="_blank">
          <img src="/images/btn_ios.png" className=
            'mb-[-30px]' />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.goball.crypto" target="_blank">
          <img src="/images/btn_android.png" className=
            'mt-[-30px]' />
        </a></div> : null}
      <Popup
        visible={isModalVisible}
        footer={null}
        onCancel={() => setIsModalVisible(false)}
        width="400px"
      > <span className='font-bold text-base text-white'> Account {getEllipsisTxt(walletAddress, 10)}</span>
        <button onClick={async () => {
          await logout()
          window.localStorage.removeItem('connectorId')
          setIsModalVisible(false)
        }} className="w-full min-w-[150px] bg-[#D72364] rounded-full mt-1 h-9 flex items-center justify-center mr-3 text-black">
          <p className="text-base	 font-extrabold">Disconnect Wallet</p>
        </button>
      </Popup>
      {isShowMenu && (
        <div className='fixed h-full w-full bg-[#00000080] z-50'>
          <button className='fixed h-full w-full z-30' onClick={() => {
            setIsShowMenu(false)
          }} />
          <div className="fixed h-full w-2/3 bg-[#1A2D75] z-50 overflow-y-scroll">
            <span
              className="absolute top-4 left-4"
              onClick={() => {
                setIsShowMenu(false)
              }}
            >
              <i className="fa-solid fa-xmark text-2xl"></i>
            </span>
            <button onClick={() => {
                navigate('/')
                setIsShowMenu(false)}} className="mt-8 m-auto flex justify-center">
              <img src="/logo.png" />
            </button>
            <div className='flex flex-row items-center justify-center mt-4 font-black'>
              <span className='mr-2 text-main-orange'>
                Contract
              </span>
              <a href={"https://bscscan.com/token/" + TOKEN} target="_blank" className='mr-4 hover:text-main-orange'>
                {getEllipsisTxt(TOKEN, 7)}
              </a>
              <Tooltip placement="right" title={'Copied'} color="#262e36" trigger='click'>
                <button className="active:bg-blue-600 rounded-[10px]" onClick={() => { navigator.clipboard.writeText(TOKEN) }}>
                  <img src="/images/ic_copy_white.png" className='h-5 mb-1' />
                </button>
              </Tooltip>

            </div>
            <Collapse bordered={false} accordion expandIconPosition="right"  expandIcon={({ isActive }) => <CaretRightOutlined style={{color:"white"}} rotate={isActive ? 90 : 0} />} className="mt-6 flex flex-col bg-[#1A2D75] text-white" defaultActiveKey={[activeTab]} onChange={onChangeCollapse}>
              <button onClick={() => {
                navigate('/')
                setIsShowMenu(false)
              }} className={'h-[60px] items-center flex px-4 ' + (props.path === '/' ? 'bg-[#0C1946] border-y-2 border-main-orange text-main-orange' : 'text-white')}>
                <span
                  className={'hover:text-main-orange font-bold text-base ' +
                    (props.path === '/'
                      ? 'text-main-orange'
                      : 'text-white')}
                >
                  HOME
                </span>
              </button>
              <button onClick={() => {
                navigate('/tokenomic')
                setIsShowMenu(false)
              }} className={'p-4 flex items-start ' + (props.path === '/tokenomic' ? 'bg-[#0C1946] border-y-2 border-main-orange text-main-orange' : 'text-white')}>
                <span
                  className={
                    'hover:text-main-orange font-bold text-base ' +
                    (props.path === '/tokenomic'
                      ? 'text-main-orange'
                      : 'text-white')
                  }
                >
                  TOKENOMICS
                </span>
              </button>
              <button onClick={() => {
                navigate('/comingsoon')
                setIsShowMenu(false)
              }} className={'p-4 flex items-start ' + (props.path === '/ipo' ? 'bg-[#0C1946] border-y-2 border-main-orange text-main-orange' : 'text-white')}>
                <span
                  className={
                    'hover:text-main-orange font-bold text-base ' +
                    (props.path === '/ipo'
                      ? 'text-main-orange'
                      : 'text-white')
                  }
                >
                  IPO
                </span>
              </button>
              <button onClick={() => {
                navigate('/comingsoon')
                setIsShowMenu(false)
              }} className={'p-4 flex items-start ' + (props.path === '/staking' ? 'bg-[#0C1946] border-y-2 border-main-orange text-main-orange' : 'text-white')}>
                <span
                  className={
                    'hover:text-main-orange font-bold text-base ' +
                    (props.path === '/staking'
                      ? 'text-main-orange'
                      : 'text-white')
                  }
                >
                  STAKE
                </span>
              </button>
              <Panel header={(
                <div className='h-[60px] items-center flex'>
                  <span
                    className={'font-bold text-base ' +
                      (props.path === '/heroes' || props.path === '/items' || props.path === '/inventory'
                        ? 'text-main-orange'
                        : 'text-white')}>
                    MARKETPLACE
                  </span>
                </div>
              )} key="marketplace" className={(props.path === '/heroes' || props.path === '/items' || props.path === '/inventory' ? '!bg-[#0C1946] !border-y-2 !border-main-orange !text-main-orange' : 'text-white')}>
                <button onClick={() => {
                  navigate('/heroes')
                  setIsShowMenu(false)
                }} className='h-[60px] ml-4 items-center flex'>
                  <span
                    className={
                      'hover:text-main-orange font-bold text-base items-center ' + (props.path === '/heroes' || props.path === '/heroes-detail'
                        ? 'text-main-orange'
                        : 'text-white')}
                  >
                    HEROES
                  </span>
                </button>
                <button onClick={() => {
                  navigate('/items')
                  setIsShowMenu(false)
                }} className='h-[60px] ml-4 items-center flex'>
                  <span
                    className={
                      'hover:text-main-orange font-bold text-base items-center ' + (props.path === '/items' || props.path === '/items-detail'
                        ? 'text-main-orange'
                        : 'text-white')}
                  >
                    ITEMS
                  </span>
                </button>
                <button onClick={() => {
                  setIsShowMenu(false)
                  if (!isAuthenticated) {
                    setIsSelectWalletModal(true)
                    return
                  }
                  navigate('/inventory')
                }} className='h-[60px] ml-4 items-center flex'>
                  <span
                    className={
                      'hover:text-main-orange font-bold text-base items-center ' + (props.path === '/inventory'
                        ? 'text-main-orange'
                        : 'text-white')}
                  >
                    INVENTORY
                  </span>
                </button>
              </Panel>
              <Panel header={(<div className='h-[60px] items-center flex'>
                <span
                  className={'font-bold text-base ' +
                    (props.path === '/tokentransfer' || props.path === '/itemtransfer' || props.path === '/logingame'
                      ? 'text-main-orange'
                      : 'text-white')}>
                  INGAME
                </span>
              </div>)} key="/ingame" className={(props.path === '/tokentransfer' || props.path === '/itemtransfer' || props.path === '/logingame' ? '!bg-[#0C1946] !border-y-2 !border-main-orange !text-main-orange' : 'text-white')}>
                <button onClick={() => {
                  setIsShowMenu(false)
                  if (!isAuthenticated) {
                    setIsSelectWalletModal(true)
                    return
                  }
                  navigate('/tokentransfer')
                }} className='h-[60px] ml-4 items-center flex'>
                  <span
                    className={
                      'hover:text-main-orange font-bold text-base items-center ' + (props.path === '/tokentransfer'
                        ? 'text-main-orange'
                        : 'text-white')}
                  >
                    TOKEN TRANSFER
                  </span>
                </button>
                <button onClick={() => {
                  setIsShowMenu(false)
                  if (!isAuthenticated) {
                    setIsSelectWalletModal(true)
                    return
                  }
                  navigate('/itemtransfer')
                }} className='h-[60px] ml-4 items-center flex'>
                  <span
                    className={
                      'hover:text-main-orange font-bold text-base items-center ' + (props.path === '/itemtransfer'
                        ? 'text-main-orange'
                        : 'text-white')}
                  >
                    ITEMS TRANSFER
                  </span>
                </button>
                <button onClick={() => {
                  setIsShowMenu(false)
                  if (!isAuthenticated) {
                    setIsSelectWalletModal(true)
                    return
                  }
                  navigate('/logingame')
                }} className='h-[60px] ml-4 items-center flex'>
                  <span
                    className={
                      'hover:text-main-orange font-bold text-base items-center ' + (props.path === '/logingame'
                        ? 'text-main-orange'
                        : 'text-white')}
                  >
                    CREATE ACCOUNT
                  </span>
                </button>
              </Panel>
              <Panel header={(<div className='h-[60px] items-center flex'>
                <span
                  className={'font-bold text-base ' +
                    (props.path === '/airdrop' || props.path === '/refgame'
                      ? 'text-main-orange'
                      : 'text-white')}>
                  EVENT
                </span>
              </div>)} key="/event" className={(props.path === '/airdrop' || props.path === '/refgame' ? '!bg-[#0C1946] !border-y-2 !border-main-orange !text-main-orange' : 'text-white')}>

                <button onClick={() => {
                  setIsShowMenu(false)
                  if (!isAuthenticated) {
                    setIsSelectWalletModal(true)
                    return
                  }
                  navigate('/airdrop')
                }} className='h-[60px] ml-4 items-center flex'>
                  <span
                    className={
                      'hover:text-main-orange font-bold text-base items-center ' + (props.path === '/airdrop'
                        ? 'text-main-orange'
                        : 'text-white')}
                  >
                    AIRDROP
                  </span>
                </button>
                <button onClick={() => {
                  setIsShowMenu(false)
                  if (!isAuthenticated) {
                    setIsSelectWalletModal(true)
                    return
                  }
                  navigate('/refgame')
                }} className='h-[60px] ml-4 items-center flex'>
                  <span
                    className={
                      'hover:text-main-orange font-bold text-base items-center ' + (props.path === '/refgame'
                        ? 'text-main-orange'
                        : 'text-white')}
                  >
                    INVITE FRIENDS
                  </span>
                </button>
              </Panel>
              <Panel header={(<div className='h-[60px] items-center flex'>
                <span
                  className={'font-bold text-base ' +
                    (props.path === '/usermanual' || props.path === '/instruction'
                      ? 'text-main-orange'
                      : 'text-white')}>
                  INSTRUCTION
                </span>
              </div>)} key="/intruction" className={(props.path === '/usermanual' || props.path === '/instruction' ? '!bg-[#0C1946] !border-y-2 !border-main-orange !text-main-orange' : 'text-white')}>
                <button onClick={() => {
                  navigate('/usermanual')
                  setIsShowMenu(false)
                }} className='h-[60px] ml-4 items-center flex'>
                  <span
                    className={
                      'hover:text-main-orange font-bold text-base items-center ' + (props.path === '/usermanual'
                        ? 'text-main-orange'
                        : 'text-white')}
                  >
                    LOGIN GAME
                  </span>
                </button>
                <button onClick={() => {
                  navigate('/instruction')
                  setIsShowMenu(false)
                }} className='h-[60px] ml-4 items-center flex'>
                  <span
                    className={
                      'hover:text-main-orange font-bold text-base items-center ' + (props.path === '/instruction'
                        ? 'text-main-orange'
                        : 'text-white')}
                  >
                    DOWNLOAD
                  </span>
                </button>
              </Panel>


            </Collapse>
          </div>
        </div>
      )}
      <div className="lg:w-[1140px] lg:m-auto flex flex-col">
        <div className="">
          <div className="float-right mt-2 mr-2 lg:mt-10 flex flex-col">
            <div className="flex flex-row self-end items-center ">
              <div className='hidden sm:flex flex-row items-center font-black text-base'>
                <span className='mr-2 text-main-orange'>
                  Contract
                </span>
                <a href={"https://bscscan.com/token/" + TOKEN} target="_blank" className='mr-4 hover:text-main-orange'>
                  {getEllipsisTxt(TOKEN, 7)}
                </a>
                <Tooltip placement="right" title={'Copied'} color="#262e36" trigger='click'>
                  <button className="active:bg-blue-600 rounded-[10px]" onClick={() => { navigator.clipboard.writeText(TOKEN) }}>
                    <img src="/images/ic_copy_white.png" className='h-6 mb-1' />
                  </button>

                </Tooltip>
                <svg xmlns="http://www.w3.org/2000/svg" width="6" height="34" viewBox="0 0 2 24" fill="none" className='mx-6'>
                  <line x1="1" y1="1" x2="0.999999" y2="23" stroke="#8A8CB2" strokeWidth="2" strokeLinecap="round" />
                  <line x1="1" y1="1" x2="0.999999" y2="23" stroke="white" strokeOpacity="0.2" strokeWidth="2" strokeLinecap="round" />
                </svg>
              </div>

              <div
                className="connect-wallet h-8 lg:h-12 text-black p-1.5 lg:p-3.5 lg:w-[190px] w-[120px] font-bold self-end cursor-pointer"
                onClick={() => {
                  if (isAuthenticated) {
                    setIsModalVisible(true)
                  } else {
                    setIsSelectWalletModal(true)
                  }
                }}
              >
                {isAuthenticated
                  ? getEllipsisTxt(walletAddress, 6)
                  : 'CONNECT WALLET'}
              </div>
              <a href={"https://game.goball.io/index.html?time=" + new Date().getTime()} target="_blank"
                className="bg-gradient-to-r from-green-400 to-blue-500 rounded-[20px] lg:w-[190px] w-[120px] text-center h-8 lg:h-12 text-black p-1.5 lg:p-3.5 font-bold self-end cursor-pointer ml-5"
              >
                PLAY GAME
              </a>
            </div>
            <div className="mt-7 hidden lg:flex flex-row gap-8 z-20">
              <Link to="/">
                <span
                  className={
                    'hover:text-main-orange font-bold ' +
                    (props.path === '/' ? 'text-main-orange' : 'text-white')
                  }
                >
                  HOME
                </span>
              </Link>
              <div className="group inline-block relative">
                <button className='flex flex-row items-center'>
                  <span
                    className={
                      'group-hover:text-main-orange font-bold ' +
                      (props.path === '/heroes' || props.path === '/items' || props.path === '/inventory'
                        ? 'text-main-orange'
                        : 'text-white')
                    }
                  >
                    MARKETPLACE
                  </span>
                  <svg
                    className={"fill-current h-4 w-4 ml-1 group-hover:text-main-orange " +
                      (props.path === '/heroes' || props.path === '/items' || props.path === '/inventory'
                        ? 'text-main-orange'
                        : 'text-white')}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                    />
                  </svg>
                </button>
                <ul className="absolute left-1/2 transform -translate-x-1/2  hidden w-max text-gray-700 pt-4 group-hover:block">
                  <li className="">
                    <Link
                      className={"rounded-t bg-main-grey text-center hover:text-main-orange font-bold py-2 px-4 block whitespace-no-wrap " + (props.path === '/heroes'
                        ? 'text-main-orange'
                        : 'text-white')}
                      to="/heroes">HEROES</Link>
                  </li>
                  <li className="">
                    <Link
                      className={"bg-main-grey hover:text-main-orange text-center font-bold py-2 px-4 block whitespace-no-wrap " + (props.path === '/items'
                        ? 'text-main-orange'
                        : 'text-white')}
                      to="/items">ITEMS</Link>
                  </li>
                  <li className="">
                    <Link
                      className={"rounded-b bg-main-grey text-white hover:text-main-orange text-center font-bold py-2 px-4 block whitespace-no-wrap " + (props.path === '/inventory'
                        ? 'text-main-orange'
                        : 'text-white')}
                      to="/inventory" onClick={(e) => {
                        if (!isAuthenticated) {
                          e.preventDefault();
                          setIsSelectWalletModal(true)
                        }
                      }}>INVENTORY</Link>
                  </li>
                </ul>
              </div>
              <div className="group inline-block relative">
                <div className='flex flex-row items-center'>
                  <span
                    className={
                      'group-hover:text-main-orange font-bold ' +
                      (props.path === '/tokentransfer' || props.path === '/itemtransfer' || props.path === '/logingame'
                        ? 'text-main-orange'
                        : 'text-white')
                    }
                  >
                    INGAME
                  </span>
                  <svg
                    className={"fill-current h-4 w-4 ml-1 zIndex-10 group-hover:text-main-orange " +
                      (props.path === '/tokentransfer' || props.path === '/itemtransfer' || props.path === '/logingame'
                        ? 'text-main-orange'
                        : 'text-white')}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                    />
                  </svg>
                </div>
                <ul className="absolute left-1/2 transform -translate-x-1/2  hidden w-max text-gray-700 pt-4 group-hover:block">
                  <li className="">
                    <Link
                      className={"rounded-t bg-main-grey text-center hover:text-main-orange font-bold py-2 px-4 block whitespace-no-wrap " + (props.path === '/tokentransfer'
                        ? 'text-main-orange'
                        : 'text-white')}
                      to="/tokentransfer" onClick={(e) => {
                        if (!isAuthenticated) {
                          e.preventDefault();
                          setIsSelectWalletModal(true)
                        }
                      }}>TOKEN TRANSFER</Link>
                  </li>
                  <li className="">
                    <Link
                      className={"bg-main-grey hover:text-main-orange text-center font-bold py-2 px-4 block whitespace-no-wrap " + (props.path === '/itemtransfer'
                        ? 'text-main-orange'
                        : 'text-white')}
                      to="/itemtransfer" onClick={(e) => {
                        if (!isAuthenticated) {
                          e.preventDefault();
                          setIsSelectWalletModal(true)
                        }
                      }}>ITEM TRANSFER</Link>
                  </li>
                  <li className="">
                    <Link
                      className={"rounded-b bg-main-grey text-center hover:text-main-orange font-bold py-2 px-4 block whitespace-no-wrap " + (props.path === '/logingame'
                        ? 'text-main-orange'
                        : 'text-white')}
                      to="/logingame" onClick={(e) => {
                        if (!isAuthenticated) {
                          e.preventDefault();
                          setIsSelectWalletModal(true)
                        }
                      }}>CREATE ACCOUNT</Link>
                  </li>
                </ul>
              </div>
              <div className="group inline-block relative">
                <div className='flex flex-row items-center'>
                  <span
                    className={
                      'group-hover:text-main-orange font-bold ' +
                      (props.path === '/airdrop' || props.path === '/event'
                        ? 'text-main-orange'
                        : 'text-white')
                    }
                  >
                    EVENT
                  </span>
                  <svg
                    className={"fill-current h-4 w-4 ml-1 group-hover:text-main-orange " +
                      (props.path === '/airdrop' || props.path === '/event'
                        ? 'text-main-orange'
                        : 'text-white')}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                    />
                  </svg>
                </div>
                <ul className="absolute left-1/2 transform -translate-x-1/2  hidden w-max text-gray-700 pt-4 group-hover:block">
                  <li className="">
                    <Link
                      className={"rounded-t bg-main-grey text-center hover:text-main-orange font-bold py-2 px-4 block whitespace-no-wrap " + (props.path === '/airdrop'
                        ? 'text-main-orange'
                        : 'text-white')}
                      to="/airdrop" onClick={(e) => {
                        if (!isAuthenticated) {
                          e.preventDefault();
                          setIsSelectWalletModal(true)
                        }
                      }}>AIRDROP</Link>
                  </li>
                  <li className="">
                    <Link
                      className="rounded-b bg-main-grey text-white hover:text-main-orange text-center font-bold py-2 px-4 block whitespace-no-wrap"
                      to="/refgame" onClick={(e) => {
                        if (!isAuthenticated) {
                          e.preventDefault();
                          setIsSelectWalletModal(true)
                        }
                      }}>INVITE FRIENDS</Link>
                  </li>

                </ul>
              </div>
              <div className="group inline-block relative">
                <div to="/ingame" className='flex flex-row items-center'>
                  <span
                    className={
                      'group-hover:text-main-orange font-bold ' +
                      (props.path === '/usermanual' || props.path === '/instruction'
                        ? 'text-main-orange'
                        : 'text-white')
                    }
                  >
                    INSTRUCTION
                  </span>
                  <svg
                    className={"fill-current h-4 w-4 ml-1 group-hover:text-main-orange " +
                      (props.path === '/usermanual' || props.path === '/instruction'
                        ? 'text-main-orange'
                        : 'text-white')}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                    />
                  </svg>
                </div>
                <ul className="absolute left-1/2 transform -translate-x-1/2  hidden w-max text-gray-700 pt-4 group-hover:block">
                  <li className="">
                    <Link
                      className={"rounded-t bg-main-grey text-center hover:text-main-orange font-bold py-2 px-4 block whitespace-no-wrap " + (props.path === '/usermanual'
                        ? 'text-main-orange'
                        : 'text-white')}
                      to="/usermanual">LOGIN GAME</Link>
                  </li>
                  <li className="">
                    <Link
                      className={"rounded-b bg-main-grey text-center hover:text-main-orange font-bold py-2 px-4 block whitespace-no-wrap " + (props.path === '/instruction'
                        ? 'text-main-orange'
                        : 'text-white')}
                      to="/instruction">DOWNLOAD</Link>
                  </li>

                </ul>
              </div>
              <Link to="/tokenomic">
                <span
                  className={
                    'hover:text-main-orange font-bold ' +
                    (props.path === '/tokenomic'
                      ? 'text-main-orange'
                      : 'text-white')
                  }
                >
                  TOKENOMICS
                </span>
              </Link>
              <Link to="/comingsoon">
                <span
                  className={
                    'hover:text-main-orange font-bold ' +
                    (props.path === '/ipo'
                      ? 'text-main-orange'
                      : 'text-white')
                  }
                >
                  IPO
                </span>
              </Link>
              <Link to="/comingsoon">
                <span
                  className={
                    'hover:text-main-orange font-bold ' +
                    (props.path === '/staking'
                      ? 'text-main-orange'
                      : 'text-white')
                  }
                >
                  STAKE
                </span>
              </Link>
            </div>
          </div>
          <Link to={'/'} className='lg:float-left lg:m-auto lg:mt-10 lg:w-[190px] w-[97px] hidden lg:block'>
            <Logo />
          </Link>
          <i
            className="fa-solid fa-bars-staggered block lg:hidden text-2xl mt-2 ml-2"
            onClick={() => {
              setIsShowMenu(true)
            }}
          ></i>
        </div>

        {props.path === '/heroes-detail' && heroInfo ? (
          <div className="flex flex-row mt-6 lg:items-center lg:justify-center">
            <div className="relative w-full lg:w-fit lg:mr-9">
              {/* <Hero /> */}
              <div className="top-0 left-0 z-10 ml-4 lg:ml-0 flex flex-col items-start lg:items-end">
                <div className="font-title text-4xl lg:text-5xl text-stroke-black-2 text-main-green-light">
                  {heroInfo.name}
                </div>
                <span className="bg-pink-600 rounded-lg text-base px-1 w-[84px] text-center mt-2">
                  #{heroInfo.itemId}
                </span>
                <img
                  className="m-auto mt-2 lg:mt-10 lg:absolute w-[225px] h-[280px] lg:h-[486px] lg:w-[420px] z-0 lg:right-[70px] block lg:hidden"
                  src={heroInfo.image}
                  alt=""
                />
                <div className="w-full flex flex-col items-center lg:items-end">
                  {nftToBuy && (
                    <div className="mt-6 font-title text-4xl font-bold text-stroke-black-2 text-white flex flex-row gap-4 items-center justify-end">
                      {Moralis?.Units?.FromWei(nftToBuy.salePrice, 18)} BNB{' '}
                      <img src="/images/bnb.png" alt="" />
                    </div>
                  )}
                  {type === 'buy' && (
                    <div
                      onClick={buyNow}
                      className="p-1 rounded-2xl bg-main-green-light text-black text-sm font-bold w-[100px] mt-4 text-center cursor-pointer"
                    >
                      {loading ? 'Processing...' : 'BUY NOW'}
                    </div>
                  )}

                  {type === 'sell' && (
                    <div
                      onClick={sellNow}
                      className="p-1 rounded-2xl bg-red-400 text-black text-sm font-bold w-[100px] text-center cursor-pointer mt-4"
                    >
                      {loading ? 'Processing...' : 'SELL NOW'}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <img
              className="ml-9 w-[156px] h-[170px] lg:h-[450px] lg:w-[370px] z-0 lg:right-[70px] hidden lg:block"
              src={heroInfo.image}
              alt=""
            />
          </div>
        ) : null}

        {props.path === '/items-detail' && heroInfo ? (
          <div className="flex flex-row mt-12 lg:items-center lg:justify-center">
            <div className="relative w-full lg:w-fit mr-9">
              <div className="top-0 left-0 z-10 ml-4 lg:ml-0 lg:mt-20 flex flex-col items-start lg:items-end">
                <div className="font-title text-4xl lg:text-5xl text-stroke-black-2 text-main-green-light">
                  {heroInfo.name}
                </div>
                <span className="bg-pink-600 rounded-lg text-base px-1 w-[84px] text-center mt-2">
                  #{heroInfo.itemId}
                </span>
                <img
                  className="m-auto mt-2 lg:mt-10 lg:absolute w-[156px] h-[170px] lg:h-[486px] lg:w-[420px] z-0 lg:right-[70px] block lg:hidden"
                  src={heroInfo.imageUrl}
                  alt=""
                />
                <div className="w-full flex flex-col lg:items-end items-center">
                  {nftToBuy && (
                    <div className="mt-3 flex flex-row gap-3 items-center lg:justify-center">
                      <span className="font-title mt-3 text-4xl font-bold text-stroke-black-2 text-white">
                        {Moralis?.Units?.FromWei(nftToBuy.salePrice, 18)} GBS
                      </span>
                      <img src="/images/gbs.png" alt="" className="h-10 w-10" />
                    </div>
                  )}
                  {type === 'buy' && (
                    <div
                      onClick={buyNow}
                      className="p-1 rounded-2xl bg-main-green-light text-black text-sm font-bold w-[100px] mt-4 text-center cursor-pointer"
                    >
                      {loading ? 'Processing...' : 'BUY NOW'}
                    </div>
                  )}

                  {type === 'sell' && (
                    <div
                      onClick={sellNow}
                      className="p-1 rounded-2xl bg-red-400 text-black text-sm font-bold w-[100px] text-center cursor-pointer mt-4"
                    >
                      {loading ? 'Processing...' : 'SELL NOW'}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <img
              className="ml-9 w-[156px] h-[170px] lg:h-[450px] lg:w-[370px] z-0 lg:right-[70px] hidden lg:block"
              src={heroInfo.imageUrl}
              alt=""
            />
          </div>
        ) : null}


        <Popup
          title="SELL"
          visible={isSellModalVisible}
          onOk={sellHeroNow}
          onCancel={() => {
            setIsSellModalVisible(false)
          }}
        >
          <Input
            type="text"
            value={sellPrice}
            onChange={(e) => {
              setSellPrice(e.target.value)
            }}
          ></Input>

        </Popup>

        <Popup
          title="IMPORT YOUR WALLET"
          visible={isSelectWalletModal}
          footer={null}
          onCancel={() => setIsSelectWalletModal(false)}
        >
          <Button
            size="large"
            style={{
              height: 60,
              width: '100%',
              marginTop: '10px',
              borderRadius: '0.5rem',
              fontSize: '16px',
              fontWeight: '500',
            }}
            onClick={async () => {
              connectWallet('injected')
              setIsSelectWalletModal(false)
            }}
          >
            <img className='m-auto' src="/images/btn_MetaMask.png" alt="" />
          </Button>

          <Button
            size="large"
            style={{
              width: '100%',
              height: 60,
              marginTop: '10px',
              borderRadius: '0.5rem',
              fontSize: '16px',
              fontWeight: '500',
            }}
            onClick={async () => {
              connectWallet('walletconnect')
              setIsSelectWalletModal(false)
            }}
          >
            <img className='m-auto' src="/images/btn_trust_wallet.png" alt="" />
          </Button>

          <Button
            size="large"
            style={{
              width: '100%',
              height: 60,
              marginTop: '10px',
              borderRadius: '0.5rem',
              fontSize: '16px',
              fontWeight: '500',
            }}
            onClick={async () => {
              connectWallet(Coin98Connector)
              setIsSelectWalletModal(false)
            }}
          >
            <img className='m-auto' src="/images/btn_coin98.png" alt="" />
          </Button>
        </Popup>
      </div>
    </div>
  )
}

export default Header
