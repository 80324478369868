const ListMember = [
    { id: 5, name: "らんちゃん", name2: "", name3: "Ran-chan", office: "Marketing Manager" },
    { id: 6, name: "多田", name2: "ただ", name3: "Tada", office: "Head of Marketing" },
    { id: 7, name: "高森", name2: "たかもり", name3: "Takamori", office: "Content Manager" },
    { id: 8, name: "伊藤", name2: "いとう", name3: "Itou", office: "Game Design Leader" },
    { id: 9, name: "渡辺", name2: "わたなべ", name3: "Watanabe", office: "Artist Leader" },
    { id: 10, name: "姫", name2: "ひめ", name3: "Hime", office: "Game Design" },
    { id: 11, name: "ゆいちゃん", name2: " ", name3: "Yui-chan", office: "Artist" },
    { id: 12, name: "由奈", name2: "ゆな", name3: "Yuna", office: "Artist" },
    { id: 13, name: "西野", name2: "にしの", name3: "Nishino", office: "Developer" },
    { id: 14, name: "朱介", name2: "しゅすけ", name3: "Shunsuke", office: "Developer" },
    { id: 15, name: "里恵子", name2: "さとけいこ", name3: "Sato Keiko", office: "Developer" },
    { id: 16, name: "山田一郎", name2: "やまだいちろ", name3: "Yamada Ichiro", office: "Developer" },
    { id: 17, name: "松本匡", name2: "まつもと ただし", name3: "Matsumoto Tadashi", office: "Developer" },
    { id: 18, name: "木村 泉", name2: "きむらいずみ", name3: "Kimura Izumi", office: "Developer" },
    { id: 19, name: "恵子", name2: "よしこ", name3: "Yoshiko", office: "Developer" },
    {
        id: 20, name: "太郎", name2: "たろ", name3: "Taro", office: "Developer"
    },
]

const ListLeader = [
    { id: 1, name: "高橋", name2: "たかはし", name3: "Takahashi", office: "Chief Executive Officer" },
    { id: 2, name: "山本", name2: "やまもと", name3: "Yamamoto", office: "Chief Technology Officer" },
    { id: 3, name: "川島", name2: "かわしま", name3: "Kawashima", office: "Chief Marketing Officer" },
    { id: 4, name: "田中", name2: "たなか", name3: "Tanaka", office: "Project Manager" },
]
export {
    ListMember,
    ListLeader,
};
