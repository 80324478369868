import { Link } from 'react-router-dom'


const data = [
    {
        title: <p>CLICK ON THE BANNER OR USE <a className='font-bold text-[#00CCFF]' href='/ref/0' target="_blank">GOBALL REFERRAL LINK</a></p>,
        sub: ""
    },
    {
        title: 'CONNECT YOUR WALLET',
        sub: ""
    },
    {
        title: '',
        sub: <p>*We recommend <a className='font-bold text-[#00CCFF]' href='https://metamask.io/' target="_blank">Metamask wallet</a> for the best experience</p>
    },
    {
        title: 'LOG IN TO YOUR WALLER AND CLICK RECEIVE',
        sub: ''
    },
    {
        title: <p>GO TO MARKETPLACE AND CLICK ON <a className='font-bold text-[#00CCFF]' href='/inventory' target="_blank">GOBALL INVENTORY</a> TO SEE YOUR TEST NFT</p>,
        sub: ''
    },
    {
        title:  <p>GO TO INGAME THEN <a className='font-bold text-[#00CCFF]' href='/logingame' target="_blank">CREATE GOBALLIO ACCOUNT</a></p>,
        sub: ''
    },
    {
        title: 'CHANGE YOUR USERNAME AND PASSWORD',
        sub: ''
    },
    {
        title: <p>CLICK ON PLAYGAME TO JOIN THE GAME</p>,
        sub: ''
    },
    {
        title: 'ENTER YOUR ACCOUNT TO LOGIN',
        sub: ''
    },
    {
        title: 'OR YOU CAN CONNECT BY USING YOUR WALLET',
        sub: ''
    },
    {
        title: 'CHOOSE YOUR NFT',
        sub: ''
    },
    {
        title: 'OPEN GIFT AND RECEIVE YOUR DAILY REWARD',
        sub: ''
    },
    {
        title: <p>CLAIM YOUR <a className='font-bold text-[#00CCFF]' href='/' target="_blank">GOBALL DAILY TICKET</a></p>,
        sub: ''
    },
    {
        title: 'PLAYERS WILL HAVE 3 TICKET DAILY',
        sub: ''
    },
    {
        title: 'CLICK PLAY TO JOIN SOLO MODE',
        sub: ''
    },
    {
        title: 'LET’S HEAD RIGHT TO THE GAME AND START EARNING FREE USDT DAILY',
        sub: ''
    },
]

const UserManual = () => {
    return (
        <div className="w-full p-4 lg:p-0">
            <div className="lg:w-[1140px] lg:m-auto flex flex-col items-center">
                <img src="./images/title_usermanual.png" />
                {data.map((item, index) => {
                    return (
                        <div key={index} className='items-center flex flex-col mt-20 text-base'>
                            <p className='text-[#9DE40B] font-bold  mb-[10px]'>
                                {item.title}
                            </p>
                            <img src={"./images/usermanual" + (index + 1) + ".png"} />
                            {item.sub}
                        </div>
                    )
                })}

            </div>
        </div>
    )
}

export default UserManual
