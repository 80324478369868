import { useState, useEffect } from 'react'
import {
  useMoralis,
  useWeb3ExecuteFunction
} from 'react-moralis'
import { Tooltip, Form, Input, message } from 'antd'
import CONSTANT from './Constant'
import { useMoralisDapp } from './providers/MoralisDappProvider/MoralisDappProvider'
import { useNavigate } from 'react-router-dom'
import Popup from "./components/Popup"
import APIService from "./service/APIService"
const NFTAdress = CONSTANT.NFTAddress
const ADMIN_ADDRESS = window.localStorage.getItem('ADMIN_ADDRESS')


const RefGame = () => {

  const { Moralis } = useMoralis()
  const [isLoading, setIsLoading] = useState(true)
  const [sortId, setSortId] = useState(0)

  const [listRef, setListRef] = useState([])
  const { walletAddress } = useMoralisDapp()


  useEffect(() => {
    async function getInventoryHero() {
      const currentUser = await Moralis.User.current()
      let sessionToken = currentUser.attributes.sessionToken
      const headers = {
        Authorization: 'Bearer ' + sessionToken,
      }
      const response = await APIService.getInventoryHero(0, 100, "HERO", headers)
      if (response.nfts.length > 0) {
        setSortId(response.nfts[0].itemId)
      }
    }
    getInventoryHero()
    getListRef()
  }, [walletAddress])


  async function getListRef() {
    setIsLoading(true)
    const currentUser = await Moralis.User.current()
    let sessionToken = currentUser.attributes.sessionToken
    const headers = {
      Authorization: 'Bearer ' + sessionToken,
    }
    const response = await APIService.getListRef(headers)
    setListRef(response)
    setIsLoading(false)
  }


  return (
    <div className="w-full bg-cover bg-center bg-no-repeat ">
      <div className="lg:w-[1140px] lg:m-auto flex flex-col items-center p-4 lg:p-0">
        <div className="font-title text-4xl text-white self-start mt-12">
          INVITE FRIENDS
        </div>
        <div className='mt-6 flex flex-col'>
          <div className="flex flex-row gap-4 mt-2 items-center">
            <p className='text-base font-bold	'>Link Ref</p>

            <div
              className="sm:w-[340px] w-[240px] bg-[#262e36] items-center flex rounded-md h-9 px-2 text-base"
            >
              {sortId != 0 ? "https://goball.io/ref/" + sortId : null}
            </div>

            <Tooltip placement="right" title={'Copied'} color="#262e36" trigger='click'>
              <button className="active:bg-blue-600 rounded-[10px]" onClick={() => { navigator.clipboard.writeText('https://goball.io/ref/' + sortId) }}>
                <img src="/images/ic-copy.png" alt="" />
              </button>
            </Tooltip>

          </div>
        </div>
        <div className="h-[53px] w-full rounded-t-lg bg-[#252934] flex flex-row items-center mt-8 font-bold text-center">
          <span className="w-24">No</span>
          <span className="w-40">Hero ID</span>
          <span className="flex-grow">Avatar</span>
          <span className="w-80">Create</span>
        </div>
        {isLoading ? (
          <div className="w-full text-center min-h-[500px] mt-10">
            Loading...
          </div>
        ) :
          <div
            className={
              'w-full rounded-b-lg bg-black bg-opacity-60 flex flex-col items-center  mb-8 gap-5 min-h-[500px] '
            }
          >
            {listRef.map((e, i) => {
              return (
                <div
                  key={e.itemId}
                  className="w-full  flex flex-row items-center justify-around text-center mt-5"
                >
                  <span className="w-24">{i + 1}</span>
                  <span className="flex flex-row w-40 items-center justify-center">
                    {e.itemId}

                  </span>
                  <span className="flex-grow items-center">  <img
                    src='https://cdn.goball.io/char/char.png'
                    alt="avatar"
                    className="w-12 h-12 m-auto"
                  /></span>
                  <span className="w-80">
                    {e.createdAt}
                  </span>
                </div>
              )
            })}
          </div>}
      </div>
    </div >
  )
}

export default RefGame
