
const Tokenomic = () => {
  return (
    <div className="w-full bg-cover bg-center bg-no-repeat bg-marketplace p-4 lg:p-0">
      <div className="lg:w-[1140px] lg:m-auto flex flex-col items-center">
        <div className="font-title text-4xl text-white self-start">SUMMARY</div>
        <div className="flex flex-col w-full mt-6 gap-4">
          <div className="flex flex-row items-center justify-between">
            <span className="w-48">Seed Sale</span>
            <span className="w-14 text-main-orange">4.5%</span>
            <span className="w-full border-t border-gray-600"></span>
            <span className="w-48 text-right font-bold">135 000 000</span>
          </div>
          <div className="flex flex-row items-center justify-between">
            <span className="w-48">Private Sale</span>
            <span className="w-14 text-main-orange">3%</span>
            <span className="w-full border-t border-gray-600"></span>
            <span className="w-48 text-right font-bold">90 000 000</span>
          </div>
          <div className="flex flex-row items-center justify-between">
            <span className="w-48">Public Sale</span>
            <span className="w-14 text-main-orange">6%</span>
            <span className="w-full border-t border-gray-600"></span>
            <span className="w-48 text-right font-bold">180 000 000</span>
          </div>
          <div className="flex flex-row items-center justify-between">
            <span className="w-48">Team</span>
            <span className="w-14 text-main-orange">15%</span>
            <span className="w-full border-t border-gray-600"></span>
            <span className="w-48 text-right font-bold">450 000 000</span>
          </div>
          <div className="flex flex-row items-center justify-between">
            <span className="w-48">Advisor</span>
            <span className="w-14 text-main-orange">5%</span>
            <span className="w-full border-t border-gray-600"></span>
            <span className="w-48 text-right font-bold">150 000 000</span>
          </div>
          <div className="flex flex-row items-center justify-between">
            <span className="w-48">Marketing</span>
            <span className="w-14 text-main-orange">10%</span>
            <span className="w-full border-t border-gray-600"></span>
            <span className="w-48 text-right font-bold">300 000 000</span>
          </div>
          <div className="flex flex-row items-center justify-between">
            <span className="w-48">Liquidity</span>
            <span className="w-14 text-main-orange">10%</span>
            <span className="w-full border-t border-gray-600"></span>
            <span className="w-48 text-right font-bold">300 000 000</span>
          </div>
          <div className="flex flex-row items-center justify-between">
            <span className="w-48">Treasury</span>
            <span className="w-14 text-main-orange">5%</span>
            <span className="w-full border-t border-gray-600"></span>
            <span className="w-48 text-right font-bold">150 000 000</span>
          </div>

          <div className="flex flex-row items-center justify-between">
            <span className="w-48">Airdrop</span>
            <span className="w-14 text-main-orange">1.5%</span>
            <span className="w-full border-t border-gray-600"></span>
            <span className="w-48 text-right font-bold">45 000 000</span>
          </div>
          <div className="flex flex-row items-center justify-between">
            <span className="w-48">Skill to earn</span>
            <span className="w-14 text-main-orange">40%</span>
            <span className="w-full border-t border-gray-600"></span>
            <span className="w-48 text-right font-bold">1 200 000 000</span>
          </div>
        </div>

        {/* <div className="font-title text-4xl text-white self-start mt-10">
          TOKEN RELEASE SCHEDULE
        </div>
        <div className="mt-2 w-full flex flex-col gap-6">
          <div className="flex flex-row">
            <div className="token-red w-[215px] h-[120px] lg:h-[66px] flex justify-center items-center capitalize">
              Lorem ipsum
            </div>
            <div className="w-full h-[120px] lg:h-[66px] border-2 border-dashed border-black flex items-center px-7 rounded-r-xl">
              Lorem ipsum, or lipsum as it is sometimes known, is dummy text
              used in laying out print, graphic or web designs.
            </div>
          </div>

          <div className="flex flex-row">
            <div className="token-pink w-[215px] h-[120px] lg:h-[66px] flex justify-center items-center capitalize">
              Lorem ipsum
            </div>
            <div className="w-full h-[120px] lg:h-[66px] border-2 border-dashed border-[#B031B2] flex items-center px-7 rounded-r-xl">
              Lorem ipsum, or lipsum as it is sometimes known, is dummy text
              used in laying out print, graphic or web designs.
            </div>
          </div>

          <div className="flex flex-row">
            <div className="token-purple w-[215px] h-[120px] lg:h-[66px] flex justify-center items-center capitalize">
              Lorem ipsum
            </div>
            <div className="w-full h-[120px] lg:h-[66px] border-2 border-dashed border-[#3A53AD] flex items-center px-7 rounded-r-xl">
              Lorem ipsum, or lipsum as it is sometimes known, is dummy text
              used in laying out print, graphic or web designs.
            </div>
          </div>
          <div className="flex flex-row">
            <div className="token-green w-[215px] h-[120px] lg:h-[66px] flex justify-center items-center capitalize">
              Lorem ipsum
            </div>
            <div className="w-full h-[120px] lg:h-[66px] border-2 border-dashed border-[#219A8C] flex items-center px-7 rounded-r-xl">
              Lorem ipsum, or lipsum as it is sometimes known, is dummy text
              used in laying out print, graphic or web designs.
            </div>
          </div>
          <div className="flex flex-row">
            <div className="token-green2 w-[215px] h-[120px] lg:h-[66px] flex justify-center items-center capitalize">
              Lorem ipsum
            </div>
            <div className="w-full h-[120px] lg:h-[66px] border-2 border-dashed border-[#519F36] flex items-center px-7 rounded-r-xl">
              Lorem ipsum, or lipsum as it is sometimes known, is dummy text
              used in laying out print, graphic or web designs.
            </div>
          </div>
        </div> */}
        {/* 
        <div className="font-title text-4xl text-white self-start mt-6">
          SALE ROUND
        </div>
        <div className="h-[53px] w-full rounded-t-lg bg-[#252934] flex flex-row items-center mt-8 font-bold text-center">
          <span className="flex-1">ROUND</span>
          <span className="flex-1">TOKEN PRICE</span>
          <span className="flex-1">RAISED</span>
          <span className="flex-1">DATE</span>
          <span className="flex-1">STATUS</span>
          <span className="flex-1">TOKEN AMOUNT</span>
        </div>
        <div className="h-[249px] w-full rounded-b-lg bg-black bg-opacity-60 flex flex-col  items-center justify-center mb-8 gap-5">
          <div className="w-full  flex flex-row items-center justify-around text-center">
            <span className="flex-1">Lorem Ipsum</span>
            <span className="flex-1">$0.05</span>
            <span className="flex-1">$177,000</span>
            <span className="flex-1">Apr 2022</span>
            <span className="flex-1">Close</span>
            <span className="flex-1">3,500,000</span>
          </div>
          <div className="w-full  flex flex-row items-center justify-around text-center">
            <span className="flex-1">Lorem Ipsum</span>
            <span className="flex-1">$0.05</span>
            <span className="flex-1">$177,000</span>
            <span className="flex-1">Apr 2022</span>
            <span className="flex-1">Close</span>
            <span className="flex-1">3,500,000</span>
          </div>
          <div className="w-full  flex flex-row items-center justify-around text-center">
            <span className="flex-1">Lorem Ipsum</span>
            <span className="flex-1">$0.05</span>
            <span className="flex-1">$177,000</span>
            <span className="flex-1">Apr 2022</span>
            <span className="flex-1">Close</span>
            <span className="flex-1">3,500,000</span>
          </div>
          <div className="w-full  flex flex-row items-center justify-around text-center">
            <span className="flex-1">Lorem Ipsum</span>
            <span className="flex-1">$0.05</span>
            <span className="flex-1">$177,000</span>
            <span className="flex-1">Apr 2022</span>
            <span className="flex-1">Close</span>
            <span className="flex-1">3,500,000</span>
          </div>
          <div className="w-full  flex flex-row items-center justify-around text-center">
            <span className="flex-1">Lorem Ipsum</span>
            <span className="flex-1">$0.05</span>
            <span className="flex-1">$177,000</span>
            <span className="flex-1">Apr 2022</span>
            <span className="flex-1">Close</span>
            <span className="flex-1">3,500,000</span>
          </div>
        </div> */}

        <div className="font-title text-4xl text-white self-start mt-16">
          TOKEN ALLOCATION
        </div>
        <div className="mt-6 w-full lg:pl-12 flex flex-row justify-center">
          <img
            className="hidden lg:block w-[690px]"
            src="./images/allocation.png"
            alt=""
          />
          <img
            className="block lg:hidden"
            src="./images/allocation-mobile.png"
            alt=""
          />
        </div>
        <div className="font-title text-4xl text-white self-start mt-20">
          TOKEN RELEASE SCHEDULE
        </div>
        <div className="mt-6 w-full  flex flex-row justify-center">
          <img
            className="hidden w-[900px] lg:block border rounded-[20px]"
            src="/images/roadmapchart.png"
            alt=""
          />
          <img
            className="block lg:hidden rounded-[10px]"
            src="/images/roadmapchart.png"
            alt=""
          />
        </div>
        <div className="font-title text-4xl text-white self-start mt-20">
          VESTING SCHEDULE
        </div>
        <div className="mt-6 w-full  flex flex-row justify-center">
          <img
            className="w-full flex"
            src="/images/vesting.png"
            alt=""
          />
        </div>
      </div>
    </div>
  )
}

export default Tokenomic
