import React, { useState, useEffect } from 'react'
import { useMoralis, useMoralisWeb3Api, useWeb3ExecuteFunction } from 'react-moralis'
import APIService from "./service/APIService"
import { useMoralisDapp } from './providers/MoralisDappProvider/MoralisDappProvider'
import { InputNumber, message, Pagination } from 'antd'

const ItemTransfer = () => {
  const { walletAddress } = useMoralisDapp()
  const [listHero, setListHero] = useState([])
  const [listItem, setListItem] = useState([])
  const [indexHeroItems, setIndexHeroItems] = useState(0)
  const { Moralis } = useMoralis()

  const [currentPage, setCurrentPage] = useState(1)
  const [totalItem, setTotalItem] = useState(0)

  useEffect(() => {
    if (!walletAddress) return
    getInventory()
  }, [walletAddress])

  useEffect(() => {
    if (listHero.length > 0 && listHero[indexHeroItems].itemId != 0) {
      getListItemByHero(listHero[indexHeroItems].itemId)
    }
  }, [currentPage])


  async function getInventory() {
    const currentUser = await Moralis.User.current()
    let sessionToken = currentUser.attributes.sessionToken
    const headers = {
      Authorization: 'Bearer ' + sessionToken,
    }
    const response = await APIService.getInventoryHero(0, 100, "HERO", headers)
    setListHero(response.nfts)
  }



  async function getListItemByHero(idHero) {
    const _offset = (currentPage - 1) * 10
    const currentUser = await Moralis.User.current()
    let sessionToken = currentUser.attributes.sessionToken
    const headers = {
      Authorization: 'Bearer ' + sessionToken,
    }
    const response = await APIService.getItemsByHero(idHero, _offset, headers)
    setListItem(response.items)
    setTotalItem(response.total)
  }

  async function mintNFT(idHero, idItem) {
    const currentUser = await Moralis.User.current()
    let sessionToken = currentUser.attributes.sessionToken
    const headers = {
      Authorization: 'Bearer ' + sessionToken,
    }
    const response = await APIService.mintNFT(
      idHero, idItem,
      headers
    )
    if (response) {
      getListItemByHero(idHero)
    }
    // setListHero(response.data.data.nfts)
  }

  return (
    <div className="lg:w-[1140px] lg:m-auto flex flex-col items-center p-4 lg:p-0">
      <div className="font-title text-4xl text-white self-start mt-12">
        ITEM TRANSFER
      </div>
      <>
        <div className="flex flex-row gap-4 mt-2 items-end mt-8">
          <div className="w-[221px]">
            <p>Choose Hero NFT</p>
            <select
              className="w-full bg-[#262e36] rounded-md mt-1 h-9 px-1"
              value={indexHeroItems}
              onChange={(e) => {
                console.log(e.target.value);
                setIndexHeroItems(e.target.value)
              }}
            >
              {listHero.map((item, index) => {
                return (
                  <option key={index} value={index}>{item.name}</option>
                )
              })}
            </select>
          </div>
          <button onClick={() => {
            setCurrentPage(1)
            getListItemByHero(listHero[indexHeroItems].itemId)
          }} className="w-[143px] bg-[#9DE40B] rounded-full mt-1 h-9 flex items-center justify-center  text-black button-scale" disabled={listHero.length == 0}>
            <p className="font-extrabold">SYNC DATA</p>
          </button>
        </div>
        <div className="h-[53px] w-full rounded-t-lg bg-[#252934] flex flex-row items-center justify-around mt-8 font-bold text-center px-2 ">
          <span className="w-14 sm:w-36 lg:w-50">NO</span>
          <span className="w-20 sm:w-48 lg:w-80 flex seft-start sm:pl-12 ">ITEM NAME</span>
          <span className="w-20 sm:w-48 lg:w-80">Item code</span>
          <span className="w-14 sm:w-36 lg:w-50">Avatar</span>
          <span className="flex-grow min-w-[120px]"></span>
        </div>
        <div className="w-full overflow-auto min-h-[300px] rounded-b-lg bg-black bg-opacity-60 flex flex-col items-center mb-8 gap-5 pt-4 pb-4 px-2">
          {listItem.map((e, i) => {
            return (
              <div
                key={e.id}
                className="w-full  flex flex-row items-center justify-around text-center"
              >
                <span className="w-14 sm:w-36 lg:w-50">
                  {i + 1}
                </span>
                <div>
                  <span className="flex w-20 sm:w-48 lg:w-80 font-black uppercase seft-start sm:pl-12">
                    {e.name}
                  </span>
                </div>
                <span className="w-20 sm:w-48 lg:w-80">
                  #{e.id}
                </span>
                <span className="w-14 sm:w-36 lg:w-50">
                  <div className='flex bg-[#0E1527] h-[46px] w-[46px] m-auto items-center justify-center rounded-lg text-sm'>
                    <img
                      className='h-[30px]'
                      src={e.imageUrl}
                    />
                  </div>

                </span>
                <span className="flex-grow min-w-[120px]">
                  <button onClick={() => {
                    mintNFT(listHero[indexHeroItems].itemId, e.id)
                  }}>
                    <img
                      className='m-auto'
                      src="./images/btn_mintnft.png"
                    />
                  </button>

                </span>

              </div>
            )
          })}
        </div>
        <div className='flex flex-grow flex-col items-center'>
          <Pagination
            className=""
            current={currentPage}
            onChange={(page) => {
              setCurrentPage(page)
            }}
            showSizeChanger={false}
            total={totalItem}
            hideOnSinglePage={false}
            pageSize={10}
          />
        </div>
      </>
    </div>
  )
}

export default ItemTransfer
