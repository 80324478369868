import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <div>
      <div className="mt-12 w-full bg-footer lg:h-48 px-4 lg:px-0">
        <div className="lg:w-[1140px] lg:m-auto flex flex-col lg:flex-row justify-between pt-6">
          <div className="flex flex-row justify-between">
            <div className="flex flex-col gap-1">
              <p className="text-xl lg:text-5xl font-bold text-gray-500">
                GOBALL
              </p>
             
              <p className="text-xs lg:text-base text-main-green-light">
                Mail: <a href="mailto:contact@goball.io">contact@goball.io</a> 
              </p>
            </div>
            <div className="flex flex-row gap-2 justify-evenly h-6 lg:hidden">
              <img src="/images/fb.png" />
              <img src="/images/yt.png" />
              <img src="/images/tw.png" />
            </div>
          </div>
          <div className="flex flex-row my-6 lg:my-0 justify-between lg:w-[600px] ">
            <div className="flex flex-col gap-2 text-xs">
              <Link to="/comingsoon">
                <p className="text-main-green-light font-bold">ABOUT</p>
              </Link>
              <a href="https://whitepaper.goball.io" target="_blank">
                White Paper
              </a>
              <a
                href="https://whitepaper.goball.io/welcome-to-goball/project-overview"
                target="_blank"
              >
                <p className="">Project Overview</p>
              </a>
              <Link to="/tokenomic">
                <p className="">Token Metrics </p>
              </Link>
              <Link to="/instruction">
                <p className="">Game Instructions </p>
              </Link>
            </div>
            <div className="flex flex-col gap-2 text-xs">
              <p className="text-main-green-light font-bold">SOCIAL</p>
              <a href="https://t.me/GoBallOffical" target="_blank">
                Telegram
              </a>
              <Link to="/comingsoon">
                Discord
              </Link>
              <a href="https://twitter.com/GoBall_io" target="_blank">
                Twitter
              </a>
              <Link to="/comingsoon">
                Medium
                </Link>
            </div>

            <div className="flex flex-col gap-2 text-xs">
              <p className="text-main-green-light font-bold">OTHERS</p>
              <Link to="/comingsoon">
                <p className="">Official Token </p>
              </Link>
              <Link to="/comingsoon">
                <p className="">Help Center </p>
              </Link>
              <Link to="/termsofservices">
                <p className="">Terms Of Service</p>
              </Link>
              <Link to="/usermanual">
                <p className="">User Manual</p>
              </Link>
            </div>
          </div>

          <div className="flex flex-row justify-center mb-6 lg:mb-0 lg:flex-col gap-3">
            {/* <a href="https://pancakeswap.finance/" target="_blank">
              <div className="p-2 rounded-2xl bg-main-orange text-black text-sm font-bold">
                BUY GOBALL
              </div>
            </a> */}
            <div className="lg:flex flex-row gap-2 justify-evenly hidden ">
              <a href="https://www.facebook.com/Goball-100135432647036/" target="_blank">
                <img src="./images/fb.png" />
              </a>
              <img src="./images/yt.png" />
              <a href="https://twitter.com/GoBall_io" target="_blank">
                <img src="./images/tw.png" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full bg-footer2 h-10">
        <p className="text-main-green-light text-center leading-10">
          Copyrights©2022 . goball
        </p>
      </div>
    </div>
  )
}

export default Footer
