import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import APIService from "./service/APIService"
import { useMoralisDapp } from './providers/MoralisDappProvider/MoralisDappProvider'
import { message } from 'antd'
import { useMoralis, useMoralisWeb3Api, useWeb3ExecuteFunction } from 'react-moralis'

async function getRef(owner, referer) {
  try {
    if (!owner) {
      message.error({ content: 'Please connect your wallet', key: "load" })
      return
    }
    const response = await APIService.getRef(owner, referer)
    return response
  } catch (error) {
    console.error(error)
  }
}

const TOKEN = '0x56d2B7cC0fC9AF4E8f1FF7321b8252d34F5095a8'
//0x263659a0268C3097a84FA1bB955f60241692D569
const Airdrop = () => {

  let { id } = useParams()
  const { walletAddress } = useMoralisDapp()
  const contractProcessor = useWeb3ExecuteFunction()

  async function contractAirdrop() {
    message.loading({ content: 'Loading...', key: "load", duration: 0 });
    const ops = {
        contractAddress: TOKEN,
        functionName: 'claimToken',
        abi: [
          {
            inputs: [],
            name: "claimToken",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function"
          },
        ],
        params: {},
    }
    await contractProcessor.fetch({
        params: ops,
        onSuccess: async (data) => {
          message.success({ content: 'Success!', key: "load" })
        },
        onError: (error) => {
          const err = error.data ? error.data.message : error.message
          message.error({ content: err, key: 'load', duration: 4 })
        },
    })
  }

  return (
    <div className="w-full bg-center bg-no-repeat bg-marketplace ">
      <div className="lg:w-[1140px] lg:m-auto flex flex-col p-4 lg:p-0 items-center">
        <div className="font-title text-4xl text-white self-start mt-12">
          TIME FOR CLAIMING GOBALL AIRDROP
        </div>
        <div className='lg:w-[730px] lg:h-[600px] mt-14 flex flex-col items-center '>
          <p className=' '>
            Through two events of Airdrop 1 and Airdrop 2, we are very grateful for your trust and enthusiastic support. And now it's time for those contributions to pay off, hit the Receive button right below to receive GoBall token rewards for the winning wallets!!
            <br />
            - Airdrop 1:<br />
            Top 10 referral : Total reward 85.000 Token<br />
            Other 6015 user in winner list: 152 Token/user<br />
            - Airdrop 2: <br />
            2500 Player in Alpha test : 559 token/player <br />
            Player in Alpha test+ report bug : 1118 token/player <br /><br />
            Congratulations to the winners of Goball - the leading game of Skill to Earn.
          </p>
          <div className='lg:w-[540px] bg-[#321C71] flex lg:flex-row flex-col py-5 px-4 lg:px-0 rounded-2xl mt-[60px]'>
            <div className='lg:w-1/2 w-full relative'>
              <img src="../images/img-ref.png" className='absolute lg:w-[85%] w-2/3 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2' />
            </div>
            <div className='w-full lg:w-1/2 flex flex-col mt-20 lg:mt-0'>
              <p className='text-lg font-black'>CLAIMING GOBALL AIRDROP</p>
              <button onClick={ () => {
               contractAirdrop()
              }} className='relative mt-4 button-scale'>
                <img src="../images/btn-ref.png" className='rounded-xl' />
                <p className='absolute top-1/2 left-2/3 transform -translate-x-2/3 -translate-y-1/2 text-3xl font-black'>CLAIM</p>
              </button>
            </div>
          </div>
        </div>

      </div>
    </div >
  )
}

export default Airdrop
