import { Modal, Form, Input, message } from 'antd'

const Popup = (props) => {
    const { title, children, visible, onOk, onCancel, txtConfirm = "CONFIRM", footer = true, style } = props
    return (
        <Modal
            className='custom-modal'
            visible={visible}
            centered
            style={style}
            closable={false}
            footer={null}
        >
            <div className='flex z-40'>
                <div className='bg-[#252934] m-auto px-4 py-5 rounded-lg border-[#7bb208] border-[1px] relative'>
                    <button className='absolute top-0 right-0' onClick={onCancel} >
                        <img src="/images/ic_closepopup.png" alt="" />
                    </button>

                    <p className='font-bold text-base mb-5 text-white'>{title}</p>
                    {children}
                    {footer && (<div className='flex flex-row justify-between mt-4'>
                        <button onClick={onCancel} className="w-full min-w-[130px] bg-[#D72364] rounded-full mt-1 h-9 flex items-center justify-center mr-3 text-black">
                            <p className="text-base	 font-extrabold">CLOSE</p>
                        </button>
                        <button onClick={onOk} className="w-full min-w-[130px] bg-[#9DE40B] rounded-full mt-1 h-9 flex items-center justify-center ml-3 text-black">
                            <p className="text-base	font-extrabold">{txtConfirm}</p>
                        </button>
                    </div>)}

                </div>

            </div>

        </Modal>
    )
}
export default Popup    